import React from 'react';
import { VStack, Text } from '@chakra-ui/react';
import HeroSection from '../components/common/HeroSection';
import Content from '../components/selectivemutism/Content';
import VideoSection from '../components/home/VideoSection';
import JoinCommunity from '../components/common/JoinCommunity';
import { useTranslation } from "react-i18next";
import MiniNavbar from '../components/common/MiniNavbar'; 

const SelectiveMutism = () => {
    const { t } = useTranslation();
    const selectiveMutism = t('selectiveMutism', { returnObjects: true })
    return (
        <VStack spacing={0}>
            <HeroSection />
            {/* <JoinCommunity from={"kidssmass"}/> */}
            <MiniNavbar /> 
            <Content content={selectiveMutism} />
        </VStack>
    );
};

export default SelectiveMutism;
