import React, {  } from "react";
import {
    Box,
    useMediaQuery,
    
} from "@chakra-ui/react";
import { useDirection } from "../../context/DirectionContext";

const Content = () => {
    const [isMobileView] = useMediaQuery("(max-width: 991px)");
    const { direction } = useDirection();

    return (
        <Box
            textAlign={"center"}
            position="relative"
            width={isMobileView ? "100%" : "80%"}
            mt={isMobileView ? 5 : 10}
            pt={isMobileView ? 5 : 10}
            opacity={1.0}
            dir={direction === "rtl" ? "rtl" : "ltr"}
        >
            {/* <AspectRatio height={500} position={'relative'} > */}
                <iframe
                    style={{height:400, position:"relative", width: '100%'}}
                    title='selective mutism'
                    src='https://mappy.s3.eu-central-1.amazonaws.com/images/devideo.mp4'
                    allowFullScreen
                />
            {/* </AspectRatio> */}
        </Box>
    );
};

export default Content;
