import React from 'react';
import { VStack, Text } from '@chakra-ui/react';
import HeroSection from '../components/common/HeroSection';
import Content from '../components/aboutus/Content';
import { useTranslation } from "react-i18next";
import MiniNavbar  from '../components/common/MiniNavbar'; 
const AboutUs = () => {
    const { t } = useTranslation();
    const content = t('about', { returnObjects: true });

    return (
        <VStack spacing={0}>
            <HeroSection />
            <MiniNavbar /> 
            <Content content={content}/>
        </VStack>
    );
};

export default AboutUs;
