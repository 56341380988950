import React, { useState, useEffect, useCallback } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

const LanguageSwitch = () => {
  const { i18n } = useTranslation();
  const [isMobileView] = useMediaQuery("(max-width: 991px)");
  const [language, setLanguage] = useState("");

  const changeLanguage = useCallback((lang, langName) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("appLanguage", lang);
    setLanguage(langName);
  }, [i18n])


useEffect(() => {
  const savedLang = localStorage.getItem("appLanguage") || "en";
  const langName = savedLang === "en" ? "English" : savedLang === "pl" ? "Polish" : savedLang === "ar" ? "Arabic" : "German";
  setLanguage(langName);
  changeLanguage(savedLang, langName);
}, [changeLanguage]);

  return (
    <Menu>
      <MenuButton
        size={isMobileView ? "sm" : "lg"}
        variant={isMobileView ? "outline" : "solid"}
        bg={isMobileView ? "transparent" : "white"}
        as={Button}
        rightIcon={<ChevronDownIcon color={"primary.300"} />}
        _hover={{ color: "white" }}
        zIndex={999}
        mt={"10vh"}
      >
        <Text color={"primary.300"}>
          {language}{" "}
          {language === "English" ? "🇬🇧" : language === "German" ? "🇩🇪" : language === "Arabic" ? "🇦🇪" : "🇵🇱"}
        </Text>
      </MenuButton>
      <MenuList>
        <MenuItem
          onClick={() => changeLanguage("en", "English")}
          bg={language === "English" ? "primary.300" : "white"}
        >
          🇦🇪 English
        </MenuItem>
        <MenuItem
          onClick={() => changeLanguage("de", "German")}
          bg={language === "German" ? "primary.300" : "white"}
        >
          🇩🇪 German
        </MenuItem>
        <MenuItem
          onClick={() => changeLanguage("ar", "Arabic")}
          bg={language === "Arabic" ? "primary.300" : "white"}
        >
          🇦🇪 Arabic
        </MenuItem>
        <MenuItem
          onClick={() => changeLanguage("pl", "Polish")}
          bg={language === "Polish" ? "primary.300" : "white"}
        >
          🇵🇱 Polish
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default LanguageSwitch;
