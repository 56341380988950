import React, {} from "react";
import {
  Box,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDirection } from "../../context/DirectionContext";

const Content = () => {
  const { t } = useTranslation();
  const [isMobileView] = useMediaQuery("(max-width: 991px)");
  const { direction } = useDirection();

  console.log(direction, 'direction =====')
  return (
    <Box
      textAlign={"center"}
      position="relative"
      width="100%"
      mt={isMobileView ? 5:10}
      pt={isMobileView ? 5:10}
      // bgImage="url('slide.svg')" 
      opacity={1.0}
      dir={direction === "rtl" ? "rtl" : "ltr"}

    >
    
      <Box
        pl={direction === "rtl" ? 0 : "10%"}
        pr={direction === "rtl" ? "10%" : 0}
        height="auto"
        width="90%"
        backgroundColor={"transparent"}
      // mt={"-10vh"}
      >
        <Text
          textAlign={direction === "rtl" ? "right" : "left"}
          fontSize="xl"
          color="gray.700"
          mb={4}
        >
          {t("home.homeDescription")}
        </Text>
      </Box>
      <Box
        pl={direction === "rtl" ? 0 : "10%"}
        pr={direction === "rtl" ? "10%" : 0}
        height="auto"
        width="90%"
        backgroundColor={"transparent"}
        id="selectivemutism"
      >
      </Box>
    </Box>
  );
};

export default Content;
