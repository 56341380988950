import React, { useState } from "react";
import {
    List,
    ListIcon,
    ListItem,
    Box,
    HStack,
    VStack,
    Text,
    Link as ChakraLink, Flex, Heading, useMediaQuery,Divider
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import FAQModal from "./FAQModal";
import { useNavigate } from "react-router-dom";
import { PhoneIcon} from "@chakra-ui/icons";
import { FaMailBulk, FaLinkedin} from 'react-icons/fa';
import { useDirection } from "../../context/DirectionContext";

const Footer = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isMobileView] = useMediaQuery("(max-width: 991px)");
    const { direction } = useDirection();

    const navigate = useNavigate();

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleOpenFAQModal = () => {
        setIsOpen(true);
    };

    return (
        <Box
            p={4}
            color="white"
            bg={'#4FBA64'}
            bgImage={`url(${t('home.banner_images', { returnObjects: true })[3]})`}
            bgSize="cover"
            bgPosition="center"
            // position="fixed"
            bottom={0}
            left={0}
            width="100%"
            zIndex={1000}
            paddingTop={50}
            position={'relative'}
            mt={isMobileView ? 10 : 100}
        >

            <HStack width={"100%"} justify="space-between" spacing={8} display={isMobileView ? 'inline-block' : 'flex'}>
                <Flex justifyContent={'space-between'} width={{ base: "100%", md: "50%", lg: "50%" }}
                >
                    <VStack align="start" width={"100%"}>
                        <Heading fontSize={'lg'} textAlign={direction === "rtl" ?"right": "left"}>{t("company")}</Heading>

                        <ChakraLink onClick={() => navigate("/aboutus")}>{t("navbar.aboutUs")}</ChakraLink>
                        <ChakraLink
                            onClick={handleOpenFAQModal}
                            cursor="pointer"
                            _hover={{ textDecoration: "underline" }}
                        >
                            {t("faq")}
                        </ChakraLink>
                        <ChakraLink
                            onClick={() => navigate("/aboutus/#contactus")}
                            _hover={{ textDecoration: 'underline' }} >
                            {t("support")}
                        </ChakraLink>
                    </VStack>

                    <VStack width={"100%"} align={isMobileView ? "center" : "left"}>
                        <Heading fontSize={'lg'} alignItems={direction === "rtl" ?"right" :'left'}>{t("others")}</Heading>
                        <List spacing={3}>
                            <ListItem>
                                <ChakraLink href="/impressum/#impressum">
                                    {t("impressum_tab")}
                                </ChakraLink> 
                            </ListItem>
                            <ListItem>
                                <ChakraLink href="/privacy-policy/#policy">
                                    {t("join_study.policy")}
                                </ChakraLink>
                            </ListItem>
                        </List>
                    </VStack>
                </Flex>
                {!isMobileView &&
                    <Box
                        marginTop="-15%"
                        zIndex={'1000'}
                        justifyContent="center"
                        alignSelf="center"
                        width="60%"
                    >
                        {/* <Image
                            src="bike.svg"
                            alt="tree"
                            boxSize="400px"
                            objectFit="contain"
                            textAlign="center"
                            margin="0 auto"
                        /> */}
                    </Box>
                }

                <HStack w={'40%'} justify="center" mt={4} spacing={8} alignItems="left">
                    <VStack align={isMobileView ? "center" : "left"} spacing={2}>
                        <Heading fontSize={'lg'} textAlign={direction === "rtl" ?"right" :'left'}>{t("contacts")}</Heading>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={PhoneIcon}/>
                                +49 15560676362
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaMailBulk}/>
                                <ChakraLink href="mailto:contactemail@yahoo.com" isExternal>
                                    neurobee@vitafluence.ai
                                </ChakraLink>
                            </ListItem>
                            <Divider/>
                            <ListItem>
                                <ListIcon as={FaLinkedin}/>
                                <ChakraLink href="https://www.linkedin.com/company/vitafluence/posts/?feedView=all" isExternal>
                                    {t("linkedIn")}
                                </ChakraLink>
                            </ListItem>
                        </List>
                    </VStack>
                </HStack>

            </HStack>
            <Text 
                textAlign={"center"}
                mt={4} 
                dir={direction === "rtl" ? "rtl" : "ltr"}
                >
                {t("copyright")}
            </Text>

            <FAQModal isOpen={isOpen} onClose={handleCloseModal} />
        </Box>
    );
};

export default Footer;
