// index.js (or MainApp.js)
import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import AppRoutes from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import generateTheme from "./theme/index";
import "./i18n";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { DirectionProvider } from "./context/DirectionContext";

// Disable react devTools in production
if (process.env.NODE_ENV === "production") disableReactDevTools();

const queryClient = new QueryClient();

const MainApp = () => {
  const colorMode = localStorage.getItem("chakra-ui-color-mode") || "light";
  const theme = generateTheme(colorMode); // Direction is now handled in the context

  return (
    <DirectionProvider>
      <ChakraProvider theme={theme}>
        <CSSReset />
        <AppRoutes />
      </ChakraProvider>
    </DirectionProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <MainApp />
    <ReactQueryDevtools initialIsOpen={false} buttonPosition={"bottom-right"} />
  </QueryClientProvider>
);

reportWebVitals();
