import React from 'react';
import { VStack, Text } from '@chakra-ui/react';
import HeroSection from '../components/common/HeroSection';
import Content from '../components/privacy/content';
import VideoSection from '../components/home/VideoSection';
import JoinCommunity from '../components/common/JoinCommunity';
import { useTranslation } from "react-i18next";
import {useMediaQuery}  from "@chakra-ui/react";
import MiniNavbar from '../components/common/MiniNavbar'; 

const Home = () => {
  const { t } = useTranslation();
  const [isMobileView] = useMediaQuery("(max-width: 991px)");
  const content = t('privacy_policy', { returnObjects: true });

  return (
    <VStack spacing={0}>
      <HeroSection />
     {/* {isMobileView && <JoinCommunity />} */}
     <MiniNavbar/>
      <Content  content={content}/>
      {/* {!isMobileView &&<JoinCommunity />} */}
    </VStack>
  );
};

export default Home;
