import React, {useEffect} from "react";
import {
  Box,
  Text,
  VStack,
  useTheme,
  Heading,
  Flex,Link as ChakraLink
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useDirection } from "../../context/DirectionContext";

const Content = ({ content }) => {
const { colors } = useTheme();
const { direction } = useDirection();
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#policy") {
      const contactElement = document.getElementById("policy");
      if (contactElement) {
        contactElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <Box
      // textAlign={direction === "rtl" ? "right" : "left"}
      position="relative"
      width="100%"
      pt={5}
      backgroundColor={"transparent"}
      id="policy"
      dir="ltr"
    >
      <Box
        height="auto"
        width={{ base: "90%", md: "80%", lg: "80%" }}
        backgroundColor={"transparent"}
        justifyContent={"center"}
        margin={"0 auto"}
        pt={5}
      >
        <Heading size="lg" mb={4} bg={colors.primary[200]} textStyle="Title">
          {content.title}
        </Heading>

        {content.description?.map((cont, index) => (
          <Box>
            <Heading
              size="md"
              mb={4}
              mt={5}
              bg={colors.primary[50]}
              borderRadius={5}
              width={'fit-content'}
              color={colors.primary[300]}
            >
              {cont.title}
            </Heading>
            <Heading
              size="sm"
              mb={4}
              mt={5}
              bg={colors.primary[50]}
              borderRadius={5}
              width={'fit-content'}
              color={colors.primary[300]}
            >
              {cont.subtitle}
            </Heading>
            
            <Box>
              <Text mb={5}>{cont.descr} <span dangerouslySetInnerHTML={{ __html: cont.link }} /></Text>
            </Box>
          </Box>
        ))}
      </Box>

      <VStack
        align="left"
        mt={10}
        backgroundSize={"cover"}
        width="90%"
        backgroundRepeat={"no-repeat"}
        backgroundColor={"transparent"}
      >
        <Box width="90%">
        </Box>
      </VStack>
    </Box>
  );
};

export default Content;
 