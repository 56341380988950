const DEV_BACKEND_API = "http://0.0.0.0:8030/v1/api";
const PROD_BACKEND_API = "https://mappy.izola.tech/server/v1/api";


export const environment = {
  BACKEND_API:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? `${DEV_BACKEND_API}`
      : `${PROD_BACKEND_API}`,

}