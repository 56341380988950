export const pl = {
  "navbar": {
    "home": "Strona główna",
    "selective_mutism": "Mutyzm wybiórczy",
    "autism": "Autyzm",
    "kidssmass": "KIDS-SM-ASS",
    "aboutUs": "O nas",
    "participate": "Weź udział",
    "contactUs": "Kontakt",
    "joinIn": "Dołącz",
    "logo": [
      "https://mappy.s3.eu-central-1.amazonaws.com/images/vitlogo.png",
      "https://mappy.s3.eu-central-1.amazonaws.com/images/logopartner.png"
    ]
  },
 "home":{
  "welcomeMessage": "Witamy na stronie internetowej projektu KIDS-SM-ASS!",
  "welcomeMessageDescription": "Wspomagane przez AI różnicowanie diagnoz dla mutyzmu wybiórczego i zaburzenia ze spektrum autyzmu.",
  "homeDescription": "Witamy na stronie internetowej projektu KIDS-SM-ASS! KIDS-SM-ASS oznacza wspieraną przez sztuczną inteligencję diagnostykę różnicową w badaniach przesiewowych w kierunku mutyzmu wybiórczego i zaburzeń ze spektrum autyzmu. Tutaj dowiedzą się Państwo, czym są mutyzm wybiórczy (SM) i zaburzenia ze spektrum autyzmu (ASD) oraz w jaki sposób sztuczna inteligencja może wspierać wczesną diagnozę. Na tej stronie można się również dowiedzieć, w jaki sposób mogą Państwo pomóc nam lepiej wspierać dzieci z autyzmem lub mutyzmem wybiórczym.",
  "banner_images":[
      "https://mappy.s3.eu-central-1.amazonaws.com/images/bg.png",
      "https://mappy.s3.eu-central-1.amazonaws.com/images/child.svg",
      "https://mappy.s3.eu-central-1.amazonaws.com/images/partners.png",
      "https://mappy.s3.eu-central-1.amazonaws.com/images/ground.png"
    ]
  },
  "selectiveMutism": {
    "title": "Mutyzm wybiórczy",
    "question": "Czym jest mutyzm wybiórczy?",
    "description": [
      {
        "descr": "Mutyzm wybiórczy dotyka dzieci, które nie mówią w pewnych przewidywalnych sytuacjach lub do pewnych osób, podczas gdy mówienie w innych sytuacjach, a zwłaszcza do znanych osób, jest możliwe bez żadnych problemów."
      },
      {
        "descr": "„Mutyzm wybiórczy” jest jednym z zaburzeń lękowych i występuje u około 1% wszystkich dzieci, choć liczba niezgłoszonych przypadków jest prawdopodobnie jeszcze wyższa. Często błędnie zakłada się, że dziecko jest „po prostu trochę nieśmiałe”, a rodzice szukający porady są zbywani słowami „wyrośnie z tego”. Podczas gdy nieśmiałe dzieci zwykle zaczynają mówić w sytuacjach społecznych po pewnej fazie rozgrzewki i stają się coraz bardziej zrelaksowane, niemówienie dzieci z mutyzmem wybiórczym jest bardzo stabilne w czasie."
      },
      {
        "descr": "Symptomy mutyzmu wybiórczego u dzieci mogą objawiać się na różne sposoby. Na przykład, niektóre dzieci dotknięte tym zaburzeniem wydają się „jak skamieniałe” w sytuacjach wywołujących niepokój. Inne z kolei mogą odpowiadać na pytania jedynie za pomocą mimiki i gestów, krótkimi, zwięzłymi wypowiedziami, bardzo cichym głosem lub szeptem. "
      },
      {
        "descr": "Mutyzm wybiórczy może powodować znaczne utrudnienia w życiu dzieci, które z powodu lęku nie są w stanie zachowywać się tak, jak by chciały. Ma to wpływ zarówno na wyniki w nauce, jak i na komunikację z rówieśnikami lub wychowawcami przedszkolnymi/nauczycielami. W celu ustalenia, czy u dziecka występuje mutyzm wybiórczy, zaleca się przeprowadzenie szczegółowej oceny psychologicznej. "
      },
      {
        "descr": "Mutyzm wybiórczy to zaburzenie, które zwykle rozpoczyna się w wieku od 2 do 5 lat. Według aktualnych badań mutyzm wybiórczy jest spowodowany kombinacją kilku czynników, które mogą odgrywać różną rolę u różnych dzieci. Niektóre dzieci wykazują tendencję do przejawiania zachowań lękowych lub powściągliwych już w okresie niemowlęcym i reagują na nowe, nieznane bodźce napięciem i unikaniem. Jeśli dziecko wykazuje taką lękliwą osobowość, zwiększa to prawdopodobieństwo wystąpienia mutyzmu wybiórczego w późniejszym okresie. Badania wykazały, że istnieje genetyczny wpływ na rozwój mutyzmu wybiórczego. Obecność środowiska migracyjnego również wydaje się być czynnikiem ryzyka rozwoju tego zaburzenia. Pewną rolę odgrywa tu nauka nowego języka i związana z tym niepewność podczas mówienia. "
      },
      {
        "descr": "W niektórych przypadkach przyjmuje się błędne założenia dotyczące czynników rozwojowych, ale nie można ich potwierdzić na podstawie obecnego stanu badań:"
      },
      {
        "descr": "Mutyzm wybiórczy nie jest spowodowany traumatycznym doświadczeniem lub traumatyzacją dziecka. "
      },
      {
        "descr": "Z reguły mutyzm wybiórczy nie jest reakcją buntu lub odmowy mówienia. Co więcej, dotknięte nim dzieci nie są w stanie wypowiedzieć się z powodu silnego strachu, mimo że chciałyby mówić. "
      }
    ]
  },
  "autism": {
    "title": "Autyzm ",
    "question": "Czym jest autyzm?",
    "description": [
      {
        "descr": "Zaburzenie ze spektrum autyzmu charakteryzuje się trudnościami w komunikacji i interakcjach społecznych, a także zachowaniem, które odznacza się powielanymi wzorcami i powtórzeniami. Autyzm jest zaburzeniem rozwojowym i dotyka około jednej na sto osób. Autyzm może jednak objawiać się na bardzo różne sposoby. Na przykład niektóre osoby z autyzmem potrzebują więcej wsparcia w życiu codziennym niż inne i mają bardzo dobre umiejętności językowe, inne nie potrafią mówić lub mogą mówić tylko w ograniczonym zakresie. "
      },
      {
        "descr": "Osoby z autyzmem często mają trudności ze zrozumieniem mowy, wyrazu twarzy, tonu głosu lub gestów innych osób oraz z rozpoznawaniem uczuć innych, a także z wyrażaniem własnych uczuć. Może to utrudniać poruszanie się w sytuacjach społecznych."
      },
      {
        "descr": "Autyzm jest wrodzony i trwa przez całe życie, chociaż niektóre objawy mogą się zmieniać lub nawet zanikać. Od drugiego roku życia diagnoza autyzmu może zostać postawiona przez specjalistów badających tą grupę wiekową (psychoterapeutów lub specjalistów w dziedzinie psychiatrii i psychoterapii). W tym celu przeprowadza się serię badań. Są one bardzo złożone i obejmują między innymi wywiady, obserwacje behawioralne i kwestionariusze. Chociaż pierwsze objawy pojawiają się we wczesnym dzieciństwie, u niektórych osób zaburzenia ze spektrum autyzmu rozpoznaje się dopiero na bardzo późnym etapie. Może to mieć różne przyczyny, na przykład, jeśli objawy specyficzne dla autyzmu nie zostaną rozpoznane jako takie. Ponadto większość systemów opieki zdrowotnej jest przeciążona, a czas oczekiwania na wizytę diagnostyczną może być bardzo długi. "
      }
    ]
  },
  "KIDSSMASS": {
    "title": "KIDS-SM-ASS",
    "question": "Co robimy w ramach projektu KIDS-SM-ASS?",
    "introduction": "Celem projektu KIDS-SM-ASS jest opracowanie i walidacja narzędzi do badań przesiewowych, w celu poprawy diagnostyki różnicowej zaburzeń ze spektrum autyzmu (ASD) i mutyzmu wybiórczego (SM) u dzieci. Obecnie często dochodzi do błędnych diagnoz i długiego czasu oczekiwania. Dzięki gromadzeniu danych diagnostycznych podczas gry komputerowej i wspieranej przez sztuczną inteligencję analizie parametrów, takich jak ruchy gałek ocznych, mimika i głos, sytuacja ta może ulec poprawie, a dzieci dotknięte chorobą mogą szybciej otrzymać ukierunkowaną, kompleksową diagnozę i właściwe leczenie. ",
    "description": [
      {
        "title": "Opis projektu ",
        "descr": [
          "Celem projektu KIDS-SM-ASS jest opracowanie i walidacja narzędzi do badań przesiewowych, w celu poprawy diagnostyki różnicowej zaburzeń ze spektrum autyzmu (ASD) i mutyzmu wybiórczego (SM) u dzieci. Obecnie często dochodzi do błędnych diagnoz i długiego czasu oczekiwania. Dzięki gromadzeniu danych diagnostycznych podczas gry komputerowej i wspieranej przez sztuczną inteligencję analizie parametrów, takich jak ruchy gałek ocznych, mimika i głos, sytuacja ta może ulec poprawie, a dzieci dotknięte chorobą mogą szybciej otrzymać ukierunkowaną, kompleksową diagnozę i właściwe leczenie. "
        ],
        "image": ""
      },
      {
        "title": "Jaki problem chcemy rozwiązać? ",
        "descr": [
          "Podczas badań prowadzonych przez psychologów lub pediatrów, zarówno dzieci z autyzmem, jak i mutyzmem wybiórczym wykazują znaczne nakładanie się objawów. Na przykład, w sytuacjach związanych z badaniem oba te zaburzenia mogą ujawnić pewne nieprawidłowości, takie jak brak reakcji na mowę lub unikanie kontaktu wzrokowego. Utrudnia to rozróżnienie między nimi. Kluczowe znaczenie dla rozróżnienia ma, z jednej strony, znajome środowisko dziecka w domu. W tym środowisku dzieci z SM nie powinny wykazywać objawów zbieżnych z ASD. Z drugiej strony, SM i ASD opierają się też na różnych mechanizmach. "
        ],
        "image": ""
      },
      {
        "title": "A jak chcemy to rozwiązać? ",
        "descr": [
          "Nasze podejście polega zatem na przeprowadzeniu badań przesiewowych u dziecka w domu. ",
          "Pod nadzorem bliskiej osoby dziecko gra w krótką grę na tablecie. Podczas gry zbierane są różne dane, w tym ruchy gałek ocznych, mimika, głos i czas reakcji. Zebrane dane są automatycznie analizowane przy użyciu sztucznej inteligencji, a raport jest wysyłany do specjalisty prowadzącego leczenie. Zarejestrowane parametry pozwalają na wyciągnięcie wniosków dotyczących określonych cech. Na przykład, dzieci z autyzmem i zaburzeniami lękowymi różnią się od siebie i od innych grup pod względem odmiennych parametrów głosu i wzorców ruchu gałek ocznych. "
        ],
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/kidssmass2.png"
      },

      {
        "title": "",
        "descr": [
          "Podejście to oznacza mniejszy stres dla ocenianego dziecka i powinno pomóc specjalistom nie tylko zaoszczędzić czas, lecz także uzyskać informacje, potrzebne do podjęcia najlepszych decyzji dotyczących leczenia. "
        ],
        "image": ""
      },
      {
        "title": "Jakie są nasze cele związane z projektem? ",
        "descr": [
          "Opracowaliśmy już prototyp narzędzia do badań przesiewowych w kierunku autyzmu. Obejmuje on modele SI do analizy głosu oraz rozpoznawania ruchów gałek ocznych i mimiki. W ramach naszego projektu dalej rozwijamy ten prototyp i testujemy go z osobami, które będą go później używać: dziećmi i ich rodzicami. Opracowujemy również program szkoleniowy dla rodziców, aby ułatwić korzystanie z niego w domu. W końcu prowadzimy badanie kliniczne. Obejmuje ono gromadzenie danych raz w laboratorium i kilka razy w domu, przez rodziców. Dla porównania przeprowadzana jest szczegółowa diagnostyka psychologiczna, aby można było obliczyć kryteria jakości testu. Badanie to jest częścią badania SMASS dotyczącego podobieństw i różnic między mutyzmem wybiórczym a zaburzeniami ze spektrum autyzmu, które jest prowadzone przez Uniwersytet Justusa Liebiga."
        ],
        "image": ""
      }
    ],
    "financialSupportTitle": "Wsparcie finansowe ",
    "financialSupportDescription": [
      {
        "descr": "KIDS-SM-ASS jest finansowany przez Federalne Ministerstwo Edukacji i Badań Naukowych (BMBF) w ramach programu DATIpilot jako sprint innowacji. ",
        "url": "",
        "image":""
      },
      {
        "descr": "Federalne Ministerstwo Edukacji i Badań Naukowych (BMBF) opublikowało w lipcu 2023 r. wytyczne dotyczące finansowania DATIpilot z dwoma modułami: Innovation Sprints (Moduł 1) i Innovation Communities (Moduł 2). DATIpilot ma na celu uproszczenie i przyspieszenie procesów finansowania. Działa również jako przestrzeń do eksperymentowania oraz jako repozytorium doświadczeń i pomysłów dla Niemieckiej Agencji Transferu i Innowacji (DATI), która jest obecnie w fazie tworzenia. ",
        "image": "",
        "url": ""
      },
      {
        "descr": "Łącznie finansowanych jest 300 sprintów innowacji. Podczas cyklu spotkań w różnych miejscach (roadshow) DATIpilot w okresie od listopada 2023 r. do lutego 2024 r. prawie 600 indywidualnych uczestników lub zespołów zaprezentowało swoje pomysły w pięciominutowych prezentacjach. Podczas każdego z 23 wydarzeń uczestnicy sami wybierali projekty, które powinny otrzymać finansowanie. W ten sposób do programu finansowania trafiły już 153 sprinty innowacji. Dla wszystkich pozostałych była druga szansa w procedurze loterii, w której wylosowano kolejnych 147 projektów. ",
        "image": "",
        "url": ""
      },
      {
        "title": "",
        "descr": "Projekty dotyczą szerokiego zakresu tematów: Skupiają się na SI/uczeniu maszynowym, technologii medycznej/farmaceutyce oraz opiece zdrowotnej i usługach społecznych. Około 20 procent projektów koncentruje się na innowacjach społecznych. ",
        "image": "",
        "url": ""
      },
      {
        "descr": "Więcej informacji można znaleźć tutaj:",
        "image": "",
        "url": "www.bmbf.de/datipilot"
      },
      {
        "descr": "Nawiasem mówiąc, wszystkie prezentowane projekty zostały również uwiecznione w formie graficznej. Anja Riese stworzyła ten świetny zapis graficzny dla KIDS-SM-ASS-Pitch. ",
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/descr.jpg",
        "url": ""
      }
    ]
  },
  "about": {
    "title": "O nas ",
    "question": "Kim jesteśmy? ",
    "description": {
      "descr": "Naukowcy z Uniwersytetu Justusa Liebiga (JLU) w Gießen i firmy Vitafluence.ai z Kronberg współpracują przy projekcie KIDS-SM-ASS. Ze strony JLU projektem kieruje prof. dr Christina Schwenck, a z ramienia Vitafluence.ai dr Wamuyu Owotoki. "
    },
    "ourTeam": [
      {
        "name": "Prof. Dr. Christina Schwenck ",
        "title": "Uniwersytet Justusa Liebiga w Gießen ",
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/christina.png",
        "descr": [
          "Christina Schwenck jest profesorem psychologii klinicznej dzieci i młodzieży na Uniwersytecie w Gießen oraz uznanym na arenie międzynarodowej ekspertem w dziedzinie mutyzmu selektywnego. Studiowała psychologię na uniwersytetach w Würzburgu i Madrycie, a doktorat z psychologii rozwojowej uzyskała na Uniwersytecie w Würzburgu. Pracowała na oddziałach psychiatrii dziecięcej i młodzieżowej, psychosomatyki i psychoterapii w szpitalach uniwersyteckich w Würzburgu, Monachium i Frankfurcie. Jest wykwalifikowaną psychoterapeutką dzieci i młodzieży (terapia behawioralna) oraz wykwalifikowaną liderką. Jej zainteresowania badawcze obejmują mutyzm wybiórczy, zaburzenia zachowania i dzieci chorych psychicznie rodziców.  ",
          "Prof. Schwenck angażuje się w poprawę zdrowia psychicznego dzieci na całym świecie w ramach IACAPAP, Międzynarodowego Stowarzyszenia Psychiatrii Dzieci i Młodzieży oraz Zawodów Pokrewnych (International Association for Child and Adolescent Psychiatry and Allied Professions). W latach 2018-2022 była Sekretarzem Generalnym IACAPAP, a obecnie pracuje w zarządzie IACAPAP jako koordynatorka Seminarium Badawczego im. Helmuta Remschmidta (HRRS). ",
          "W wolnym czasie realizuje swoją pasję fotograficzną na całym świecie. "
        ]
      },
      {
        "name": "Dr. Wamuyu Owotoki",
        "title": "Vitafluence.ai",
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/wamuyu.png",
        "descr": [
          "Dr. Wamuyu Owotoki jest współzałożycielką i dyrektorem naukowym Vitafluence.ai oraz współzałożycielką i dyrektorem zarządzającym iZola.life. Rozwija tam innowacje w dziedzinie telemedycyny, badań przesiewowych opartych na SI i metod opracowywania leków wspieranych przez SI. Studiowała farmację w Sankt Petersburgu i uzyskała doktorat z chemii medycznej w Hamburgu, a także ma 12-letnie doświadczenie w branży farmaceutycznej w dziedzinie neurologii, okulistyki i onkologii. Oprócz codziennych obowiązków jako współzałożycielka, czasami pełni rolę „mamy-taksówki”, angażuje się w wolontariat w inicjatywach charytatywnych i wspiera środowisko startupowe, inspirując i zachęcając kobiety do podejmowania działalności przedsiębiorczej.  "
        ]
      },
      {
        "name": "Alexandra Ntiso, M.Sc.  ",
        "title": "Uniwersytet Justusa Liebiga w Gießen ",
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/alexandra.png",
        "descr": [
          "Alexandra Ntiso pracuje jako asystent naukowy w Katedrze Psychologii Klinicznej Dzieci i Młodzieży (pod kierunkiem prof. dr Schwenck) na Uniwersytecie Justusa Liebiga w Gießen. Jej badania koncentrują się na podobieństwach i różnicach między mutyzmem wybiórczym a zaburzeniami ze spektrum autyzmu. Studiowała psychologię na Uniwersytecie w Trewirze i Uniwersytecie Technicznym w Chemnitz, a obecnie robi doktorat na Uniwersytecie Justusa Liebiga w Gießen. W wolnym czasie czyta i lubi długie spacery na łonie przyrody. "
        ]
      },
      
      {
        "name": "Anninka Enseroth, M.Sc. [mgr] ",
        "company": "Vitafluence.ai",
        "title": "Vitafluence.ai",
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/anninka.png",
        "descr": [
          "Anninka Enseroth pracuje jako psycholog w Vitafluence.ai, gdzie opracowuje i bada oparte na SI metody rozpoznawania autyzmu i powiązanych zaburzeń. Studiowała psychologię w Berlinie, Kairze i Gießen i ukończyła studia magisterskie na Uniwersytecie Justusa Liebiga, realizując projekt badawczy dotyczący oceny leczenia szpitalnego zaburzeń związanych z traumą. Lubi psy, kawę z kardamonem i podcasty o historii średniowiecza, których najchętniej słucha na siłowni. "
        ]
      }
    ],
  },
  "participate": {
  "title":"Jak mogę się przyłączyć? ",
   "join": [{
        "title": "Uczestnictwo?",
        "question": "Jak mogę się przyłączyć? ",
        "description": "Cieszymy się, że chce Pan/i wziąć udział! Można zostać uczestnikiem, jeśli ma Pan/i co najmniej 18 lat i ",
        "instructions": [
          {
            "descr": "jest rodzicem sprawującym opiekę nad dzieckiem w wieku 4-12 lat z ",
            "instructr": [
              "istniejącym (podejrzenie) rozpoznaniem mutyzmu selektywnego lub ",
              "istniejącym (podejrzenie) rozpoznaniem zaburzeń ze spektrum autyzmu lub ",
              "bez zaburzeń psychicznych. "
            ]
          }
        ]
      }],
    "getInvolved": [
      {
        "title": "Istnieje kilka sposobów, by się przyłączyć:  ",
        "instructions": [
          "1. część onlline SMASS ",
          "2. zostać beta testerem Neurobee  ",
          "3. część laboratoryjna SMASS i Neurobee   "
        ],
        "how": [
          {
            "title": "Część online SMASS  ",
            "descr": [
              {
                "descr": "W naszym badaniu sprawdzamy podobieństwa i różnice między dwiema chorobami psychicznymi, mutyzmem wybiórczym i zaburzeniami ze spektrum autyzmu, za pomocą różnych ankiet. Jest to szczególnie ważne dla diagnostyki, ale także dla zapewnienia dzieciom dotkniętym chorobą optymalnego wsparcia terapeutycznego. Chcielibyśmy również przeprowadzić ankietę wśród rodziców dzieci bez chorób psychicznych, aby ustalić różnice między dziećmi z mutyzmem wybiórczym, zaburzeniami ze spektrum autyzmu i dziećmi bez tych zaburzeń. Badanie składa się z sześciu ankiet (ok. 40 minut), które wypełnia się online. Wziąwszy udział możecie Państwo uczestniczyć w losowaniu nagród. Rozdamy łącznie 20 voucherów o wartości 20 € każdy. Czekamy na Państwa pomoc! ",
                "link": ""
              }
            ],
            "moreInfo": [
              {
                "descr": "Więcej informacji można znaleźć tutaj:",
                "bold": "",
                "link": " Probandeninfo Eltern Fragebogenteil ",
                "image": ""
              },
              {
                "descr": "W razie jakichkolwiek pytań prosimy o przesłanie wiadomości e-mail na adres:",
                "bold": "",
                "link": "StudieSMASS@psychol.uni-giessen.de",
                "image": ""
              },
              {
                "descr": "Dostęp do pierwszej części badania można uzyskać za pomocą kodu QR lub klikając poniższy link. Bardziej szczegółowe informacje na temat kontekstu badania, procedury i ochrony danych można uzyskać wcześniej na",
                "bold": "",
                "link": "https://www.soscisurvey.de/SMASS/",
                "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/qr.png"
              },
              {
                "descr": "Poszukujemy od zaraz uczestników części online SMASS. ",
                "bold": "",
                "link": "",
                "image": ""
              },
              {
                "descr": "Bitte beachten Sie:",
                "bold": "Uwaga: po wzięciu udziału w części online mogą Państwo oczywiście wziąć udział także w części laboratoryjnej i Neurobee, ale nie jest to konieczne. ",
                "link": "",
                "image": ""
              }
            ]
          },
          {
            "title": "2. Zostać beta testerem Neurobee  ",
            "descr": [
              {
                "descr": "Rozwijając Neurobee, jesteśmy oczywiście również zainteresowani tym, czy gra sprawia przyjemność dzieciom w tej grupie wiekowej i jak łatwa jest dla rodziców. W związku z tym poszukujemy beta testerów, którzy otrzymają aplikację Neurobee jak i tablet. Następnie zagrają w grę w domu ze swoim dzieckiem, po czym poprosimy Państwa i Wasze dziecko o opinie na temat różnych aspektów gry, takich jak długość, projekt i wykonalność. Poszukujemy beta testerów w listopadzie 2024 roku. Aby uzyskać więcej informacji i zarejestrować się jako beta-tester prosimy o wiadomość e-mail na adres neurobee@vitafluence.ai lub kontakt z nami za pośrednictwem formularza kontaktowego w zakładce „uczestnictwo”. ",
                "link": ""
              }
            ],
            "moreInfo": []
          },
          {
            "title": "3. Część laboratoryjna SMASS i Neurobee ",
            "descr": [
              {
                "descr": "Osoby, które wzięły udział w części SMASS online i pochodzą z regionu Gießen, mogą również wziąć udział w drugiej części badania (część laboratoryjna i Neurobee). W części laboratoryjnej będą Państwo wykonywać różne zadania ze swoim dzieckiem. Obejmuje to test IQ, zadania interakcyjne (gry) z Państwem i zadania interakcyjne (gry) z osobą nadzorującą test. Następnie Państwa dziecko czterokrotnie realizuje procedurę badań diagnostycznych o nazwie Neurobee (raz w laboratorium i trzy razy w domu) pod Państwa nadzorem. Neurobee to gra komputerowa, podczas której zbierane i analizowane są dane diagnostyczne, takie jak ruchy gałek ocznych, mimika i częstotliwość głosu. Wyniki są szczególnie interesujące dla celów diagnostycznych, ale są również ważne, jeśli chodzi o zapewnienie optymalnego wsparcia terapeutycznego dla dzieci dotkniętych chorobą. Za udział w badaniu otrzymają Państwo voucher o wartości 160 €, a Państwa dziecko drobny upominek. Więcej informacji można znaleźć tutaj:",
                "link": "Probandeninfo Eltern Laborteil und Neurobee"
              },
              {
                "descr": "Poszukujemy uczestników części laboratoryjnej SMASS z Neurobee ",
                "bold": "od grudnia 2024 roku."
              }
            ],
            "moreInfo": []
          }
        ]
      }
    ]
  },
  
  "contact": {
    "title": "Kontakt ",
    "contactUs": [
      {
        "question": "Co robić, jeśli mam więcej pytań? ",
        "description": "Jeśli mają Państwo dodatkowe pytania, można skontaktować się z nami za pośrednictwem formularza kontaktowego na tej stronie internetowej lub pocztą elektroniczną: ",
        "contact": "StudieSMASS@psychol.uni-giessen.de erreichen"
      }
    ]
  },
  "faqs": {
  "title": "Najczęściej zadawane pytania",
  "questions": [
      {
        "question": "Czym jest mutyzm wybiórczy?",
        "answer": "Mutyzm wybiórczy to złożone zaburzenie lękowe, które utrudnia osobie mówienie w określonych sytuacjach społecznych."
      },
      {
        "question": "Jak mogę pomóc osobie z mutyzmem wybiórczym?",
        "answer": "Pomaganie osobie z mutyzmem wybiórczym oznacza stworzenie wspierającego środowiska i stopniowe zachęcanie jej do mówienia w komfortowy sposób."
      }
    ]
  },
  "study": [
    "Neurobee Beta-Tester (Październik 2024 – Listopad 2024)",
    "SMASS Badanie online i laboratoryjne (Październik 2024 – Wrzesień 2025)",
    "Nie jestem jeszcze pewny"
  ],

  "join_study": {
  "phone_number": "Numer telefonu",
  "address": "Adres e-mail",
  "select_study": "Wybierz badanie, które Cię interesuje",
  "name": "Pełne imię i nazwisko",
  "nameLabel": "Jakie jest Twoje pełne imię i nazwisko?",
  "emailLabel": "Jaki jest Twój adres e-mail?",
  "phoneLabel": "Jaki jest Twój numer telefonu (opcjonalne)?",
  "studyOptionLabel": "Która część badania Cię interesuje? (można wybrać kilka opcji)",
  "descriptionLabel": "Czy jest coś jeszcze, co chciałbyś nam przekazać?",
  "privacyPolicy": "Przeczytałem i zgadzam się z",
  "dePrivacyExt": "",
  "policy": "Polityką prywatności",
  "description": "Czy jest coś jeszcze, co chciałbyś nam przekazać? (Opcjonalne)",
  "joinInTitle": "Proszę wypełnić poniższy formularz, aby dołączyć",
  "join": "Dołącz",
  "policy_check_warning": "Proszę zaznaczyć pole polityki prywatności",
  "input_warning": "Proszę wypełnić wymagane pola",
  "error_submiting_email": "Wystąpił błąd podczas dodawania Twojego adresu e-mail. Spróbuj ponownie",
  "email_sent": "Żądanie zostało pomyślnie przesłane, sprawdź swój e-mail, aby uzyskać więcej szczegółów!",
  "cancel": "Anuluj",
  "joining": "Dołączanie"
},
  "impressum": {
    "title":"Impressum",
    "address": "Vitafluence.ai GmbH Westerbachstraße 23 61476 Kronberg",
    "contacts": [
      "Telefon: +49 61747838282",
      "E-Mail: connect@vitafluence.ai"
    ],
    "description":[
      {
        "title":"Vertreten durch:",
        "subtitle": "",
        "descr": "Geschäftsführer Dr. Peter O. Owotoki Registereintrag:"
        ,"link": ""
      },
      {
        "title":"Registereintrag:",
        "subtitle": "",
        "descr": "Eingetragen im Handelsregister. Registergericht: Amtsgericht Königstein im Taunus Registernummer: HRB 10044",
        "link": ""
      },
      {
        "title":"Umsatzsteuer-ID:",
        "subtitle": "",
        "descr": "Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz: 043 240 96517",
        "link": ""
      },
      {
        "title":"Hinweis gemäß Online-Streitbeilegungs-Verordnung",
        "subtitle": "",
        "descr": "Nach geltendem Recht sind wir verpflichtet, Verbraucher auf die Existenz der Europäischen Online-Streitbeilegungs-Plattform hinzuweisen, die für die Beilegung von Streitigkeiten genutzt werden kann, ohne dass ein Gericht eingeschaltet werden muss. Für die Einrichtung der Plattform ist die Europäische Kommission zuständig. Die Europäische Online-Streitbeilegungs-Plattform ist hier zu finden:",
        "link": "<a href='http://ec.europa.eu/odr'>http://ec.europa.eu/odr</a> Unsere E-Mail lautet: <a href='mailto:neurobee@vitafluence.ai> connect@vitafluence.ai</a>"
      },

      {
        "title":"",
        "subtitle": "",
        "descr": "Wir weisen aber darauf hin, dass wir nicht bereit sind, uns am Streitbeilegungsverfahren im Rahmen der Europäischen Online-Streitbeilegungs-Plattform zu beteiligen. Nutzen Sie zur Kontaktaufnahme bitte unsere obige E-Mail und Telefonnummer."
      ,"link": ""
      },
      {
        "title":"Disclaimer – rechtliche Hinweise",
        "subtitle": "§ 1 Warnhinweis zu Inhalten",
        "descr": "Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt. Der Anbieter dieser Webseite übernimmt jedoch keine Gewähr für die Richtigkeit und Aktualität der bereitgestellten kostenlosen und frei zugänglichen journalistischen Ratgeber und Nachrichten. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder. Allein durch den Aufruf der kostenlosen und frei zugänglichen Inhalte kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen des Anbieters."
      ,"link": ""
      },
      {
        "title":"",
        "subtitle": "§ 2 Externe Links",
        "descr": "Diese Website enthält Verknüpfungen zu Websites Dritter ('externe Links'). Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht."
      ,"link": ""
      },
      {
        "title":"",
        "subtitle": "§ 3 Urheber- und Leistungsschutzrechte",
        "descr": "Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt."
      ,"link": ""
      },
      {
        "title":"",
        "subtitle": "",
        "descr": "Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig."
      ,"link": ""
      },
      {
        "title":"",
        "subtitle": "§ 4 Besondere Nutzungsbedingungen",
        "descr": "Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Paragraphen abweichen, wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen. Quelle:",
        "link":"<a styles='color: #4FBA64' href='https://www.juraforum.de/'>Hier klicken </a>"
      }
    ]
  },
  "privacy_policy": {
    "title":"Polityka prywatności",
    "description":[
      {
        "title":"Wstęp",
        "subtitle": "",
        "descr": "Dziękujemy, że zdecydowali się Państwo zostać częścią naszej społeczności Vitafluence.ai. Zobowiązujemy się chronić Państwa dane osobowe i prawo do prywatności, zgodnie z zasadami Rozporządzenia o Ochronie Danych Osobowych (RODO). W przypadku jakiekolwiek pytań lub wątpliwości dotyczących niniejszej polityki prywatności lub naszych zasad odnośnie Państwa danych osobowych, prosimy skontaktować się z nami pod adresem "
        ,"link": "<b>connect@vitafluence.ai</b>"
      },
      {
        "title":"",
        "subtitle": "",
        "descr": "Zdajemy sobie sprawę z tego, że odwiedzając naszą stronę internetową, korzystając z naszej aplikacji mobilnej lub którejkolwiek z naszych usług, powierzają nam Państwo swoje dane osobowe. W niniejszej polityce prywatności chcemy możliwie najdokładniej wyjaśnić Państwu, jakie dane gromadzimy, w jaki sposób je wykorzystujemy i jakie prawa przysługują Państwu w odniesieniu do tych danych. Prosimy o poświęcenie czasu na uważne przeczytanie tego dokumentu, ponieważ jest on ważny. Jeżeli nie zgadzają się Państwo z którymkolwiek z postanowień niniejszej polityki prywatności, prosimy o niezwłoczne poinformowanie nas o tym. ",
        "link": ""
      },
      {
        "title":"Jakie dane gromadzimy? ",
        "subtitle": "",
        "descr": "Vitafluence.ai gromadzi dane osobowe, gdy przesyłają Państwo zapytanie za pośrednictwem naszego formularza kontaktowego, aby skontaktować się z Państwem w sprawie udziału w naszym badaniu. Na Państwa prośbę i za Państwa zgodą dane mogą być również udostępniane podmiotom trzecim, takim jak np. nasi partnerzy w projekcie naukowym. Zostaną one usunięte w przypadku wycofania Państwa zgody lub zażądania usunięcia Państwa danych.",
        "link": "<ul style='margin-left: 30px'><li>dane osobowe (nazwisko, adres e-mail, nr telefonu, itp.) </li><li> treść Państwa wiadomości </li></ul>"
      },
      {
        "title":"",
        "subtitle": "",
        "descr": "Jeśli zostawią Państwo wiadomość na stronie internetowej, wówczas Państwa imię i nazwisko oraz adres e-mail również zostaną zapisane w plikach cookie. Służą one wygodzie użytkownika, dzięki czemu nie musi on ponownie wprowadzać swoich danych, gdy dodaje kolejny komentarz. Te pliki cookie są przechowywane na Państwa komputerze, dopóki ich Państwo nie usuną. ",
        "link": "<ul style='margin-left: 30px'><li><b>Adres IP iBrowser User Agent String:</b> Dane te są gromadzone po pozostawieniu komentarza. </li> <li><b>Okres przechowywania:</b> Powyższe dane są przechowywane przez czas nieokreślony, abyśmy mogli automatycznie rozpoznawać i zatwierdzać wszelkie kolejne komentarze, zamiast trzymać je w kolejce moderacji. </li></ul>"
      },

      {
        "title":"W jaki sposób gromadzimy Państwa dane?  ",
        "subtitle": "",
        "descr": "Większość gromadzonych przez nas danych przekazują Państwo bezpośrednio do dyspozycji Vitafluence.ai. Gromadzimy dane i przetwarzamy je, gdy: ",
        "link": "<ul style='margin-left: 30px'><li>wysyłają Państwo wiadomość za pośrednictwem naszego formularza kontaktowego. </li><li>Vitafluence.ai może również uzyskiwać Państwa dane pośrednio z Państwa przeglądarki jako dane wejściowe w plikach cookie. </li></ul>"
      },
      {
        "title":"Jak przechowujemy Państwa dane? ",
        "subtitle": "",
        "descr": "Vitafluence.ai w sposób bezpieczny przechowuje Państwa dane w naszej pamięci danych w chmurze, hostowanej w regionie Frankfurtu. Ściśle przestrzegamy zasady RODO, dotyczącej ograniczeń i zasad przechowywania danych; w związku z tym usuwamy lub anonimizujemy wszelkie dane osobowe, które nie są już potrzebne. Użytkownik ma również prawo do cofnięcia w dowolnym momencie dostępu i zażądania usunięcia przez nas wszystkich danych osobowych. "
      ,"link": ""
      },
      {
        "title":"Jakie prawo przysługuje Państwu w odniesieniu do swoich danych? ",
        "subtitle": "",
        "descr": "Vitafluence.ai chce mieć pewność, że są Państwo świadomi wszystkich swoich praw do ochrony danych. Każdy użytkownik ma prawo do: ",
       "link": "<ul style='margin-left: 30px'><li><b>Prawo do informacji</b> -  Mają Państwo prawo zwrócić się do Vitafluence.ai z prośbą o kopie swoich danych osobowych. </li><li><b>Prawo do sprostowania danych</b> - Mają Państwo prawo poprosić Vitafluence.ai o sprostowanie danych, które Państwa zdaniem są nieprawidłowe. Mają Państwo również prawo domagać się od Vitafluence.ai uzupełnienia informacji, które Państwa zdaniem są niekompletne </li><li><b>Prawo do usunięcia danych</b>- Mają Państwo prawo zażądać od Vitafluence.ai usunięcia swoich danych osobowych, pod pewnymi warunkami. </li><li><b>Prawo do ograniczenia przetwarzania</b> - Mają Państwo prawo zażądać, aby Vitafluence.ai, pod pewnymi warunkami, ograniczyła przetwarzanie Państwa danych osobowych. </li><li><b>Prawo do sprzeciwu wobec przetwarzania </b> - Mają Państwo prawo, pod pewnymi warunkami, sprzeciwić się przetwarzaniu Państwa danych osobowych przez Vitafluence.ai </li><li><b>Prawo do przenoszenia danych</b> - Mają Państwo prawo zażądać, aby Vitafluence.ai przekazała zebrane przez nas dane innej organizacji lub bezpośrednio Państwu, pod pewnymi warunkami. </li></ul>"
      },
      {
        "title":"",
        "subtitle": "",
        "descr": " W przypadku złożenia przez Państwa zapytania, mamy miesiąc na udzielenie odpowiedzi. Jeśli chcą Państwo skorzystać z któregokolwiek z tych praw, prosimy o skontaktowanie się z nami drogą mailową pod adresem: ",
        "link":"<b style='color: #7ce0f9'>connect@vitafluence.ai.</b>"
      },

      {
        "title":"Pliki cookie ",
        "subtitle": "",
        "descr": "Pliki cookie to pliki tekstowe, umieszczane na Państwa komputerze w celu gromadzenia standardowych danych z dziennika internetowego i informacji o zachowaniu odwiedzających. Kiedy odwiedzają Państwo nasze strony internetowe, możemy automatycznie zbierać od Państwa informacje za pomocą plików cookie lub podobnych technologii. Więcej informacji można znaleźć na stronie: allaboutcookies.org. ",
        "link":"<a style='color: #7ce0f9' href='https://www.allaboutcookies.org/'> allaboutcookies.org</a>"
      },
      {
        "title":"Jak używamy plików Cookie? ",
        "subtitle": "",
        "descr": "Vitafluence.ai wykorzystuje pliki cookie na różne sposoby, aby poprawić komfort korzystania z naszej witryny, w tym: ",
        "link":"<ul style='margin-left: 30px'><li>w celu zrozumienia, w jaki sposób korzystają Państwo z naszej witryny </li></ul>"
      },
      {
        "title":"Jakich rodzajów plików cookie używamy? ",
        "subtitle": "",
        "descr": "Istnieje wiele różnych rodzajów plików cookie, ale nasza witryna wykorzystuje następujące: ",
        "link":"<ul style='margin-left: 30px'><li><b>Funktionalność </b> - Vitafluence.ai wykorzystuje te pliki cookie, w celu możliwości rozpoznania przez nas Państwa na naszej stronie internetowej i zapamiętania wybranych wcześniej przez Państwa preferencji. Obejmuje to na przykład preferowany język i lokalizację użytkownika. Używana jest mieszanka własnych i zewnętrznych plików cookie.</li><li><b>Zachowanie związane z użytkowaniem </b> - Vitafluence.ai wykorzystuje te pliki cookie do gromadzenia informacji o Państwa wizycie na naszej stronie internetowej, przeglądanych treściach, linkach, z których Państwo korzystali oraz informacji o Państwa przeglądarce, urządzeniu i adresie IP. </li></ul>"
      },

      {
        "title":"Zarządzanie plikami cookie",
        "subtitle": "",
        "descr": "Mogą Państwo ustawić swoją przeglądarkę tak, aby nie akceptowała plików cookie, a sposób usuwania plików cookie z przeglądarki można znaleźć na wyżej wymienionej stronie internetowej (allaboutcookies.org). Jednakże w niektórych przypadkach może się zdarzyć, że niektóre funkcje naszej witryny mogą przestać działać. ",
        "link":""
      },
      {
        "title":"Polityka prywatności w ramach innych stron internetowych ",
        "subtitle": "",
        "descr": "Nasza strona internetowa może zawierać linki do i z witryn internetowych osób trzecich. Jeśli skorzystają Państwo z łącza do którejkolwiek z tych witryn, należy mieć na uwadze, że witryny te mają własne polityki prywatności i że nie ponosimy żadnej odpowiedzialności w związku z tymi politykami. Przed przesłaniem jakichkolwiek danych osobowych do tych witryn, prosimy o zapoznanie się z tymi wytycznymi. ",
        "link":""
      },
      {
        "title":"Zmiany w obrębie naszej polityki prywatności. ",
        "subtitle": "",
        "descr": "Vitafluence.ai regularnie weryfikuje swoją politykę ochrony danych osobowych. Wszelkie zmiany, które w przyszłości wprowadzimy do naszej polityki prywatności, zostaną opublikowane na tej stronie i, w stosownych przypadkach, przesłane użytkownikowi pocztą elektroniczną. Prosimy o regularne odwiedzanie tej strony, aby uzyskać informacje o wszelkich aktualizacjach lub zmianach w naszej polityce prywatności. Niniejsza Polityka prywatności została ostatnio zaktualizowana 22 października 2024. ",
        "link":""
      },
      {
        "title":"Kontakt z Vitafluence.ai ",
        "subtitle": "",
        "descr": "Jeśli mają Państwo jakiekolwiek pytania dotyczące polityki prywatności Vitafluence.ai, danych, zgromadzonych przez nas na Państwa temat, lub jeśli chcą Państwo skorzystać z któregokolwiek ze swoich praw do prywatności, prosimy o kontakt z nami. ",
        "link":"<ul style='margin-left: 30px'><li>Zadzwoń do nas: <b>+49 61747838282</b> </li><li>Lub napisz do nas na adres: <b>connect@vitafluence.ai.</b></li></ul>"

      },
      {
        "title":"Kontakt z właściwym organem.",
        "subtitle": "",
        "descr": "Jeśli chcą Państwo złożyć skargę lub uważają, że Vitafluence.ai nie rozpatrzyła Państwa sprawy w sposób zadowalający, mają Państwo możliwość zwrócenia się do Biura Inspektora ds. Ochrony Danych Osobowych.Inspektor ds. Ochrony Danych i Wolności Informacji kraju związkowego Hesja  ",
        "link":"<ul style='margin-left: 30px'><li>skrytka pocztowa 3163, 65021 Wiesbaden </li><li>poststelle@datenschutz.hessen.de</li> <li>Telefon: <b>0611-140 80</b> </li></ul>"
      }
    ]
  },
    // footer content
  "linkedIn": "LinkedIn",
  "contacts": "Kontakty",
  "company": "Firma",
  "others": "Inne",
  "support": "Pomoc i wsparcie",
  "faq": "Najczęściej zadawane pytania",
  "impressum_tab": "Impressum",
  "copyright": "© 2024 Projekt KIDS-SM-ASS"
}
