import React from 'react';
import { Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const FAQModal = ({ isOpen, onClose }) => {  // Fix destructuring of props
  const { t } = useTranslation();

  return(
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader dir='ltr'>{t('faqs.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Accordion allowToggle>
            {t('faqs.questions', { returnObjects: true }).map((faq, index) => (
              <AccordionItem key={index} border="none" mb={2} aria-expanded dir='ltr'>
                <AccordionButton>
                  <Box flex="1" textAlign={"left"}>
                    <Text fontWeight="bold" color={'primary.300'}>{faq.question}</Text>
                  </Box>
                  <AccordionIcon color={'primary.300'}/>
                </AccordionButton>
                <AccordionPanel pb={4} >
                  <Text textAlign={"left"}>{faq.answer}</Text>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
};

export default FAQModal;
