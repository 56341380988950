import React, {useEffect} from "react";
import {
  Box,
  Text,
  useTheme,
  Heading,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useDirection } from "../../context/DirectionContext";

const Content = ({ content }) => {
const { colors } = useTheme();
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#impressum") {
      const contactElement = document.getElementById("impressum");
      if (contactElement) {
        contactElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <Box
      // textAlign={direction === "rtl" ? "right" : "left"}
      dir="ltr"
      position="relative"
      width="100%"
      pt={5}
      backgroundColor={"transparent"}
      id="impressum"
    >
      <Box
        height="auto"
        width={{ base: "90%", md: "80%", lg: "80%" }}
        backgroundColor={"transparent"}
        justifyContent={"center"}
        margin={"0 auto"}
        pt={5}
      >
        <Heading size="lg" mb={4} bg={colors.primary[200]} textStyle="Title">
          {content.title}
        </Heading>

        <Text mb={5}>
          {content.address}
        </Text>

        {content.contacts.map((contact, index) => (
          <Text mb={5} key={index}>
            {contact}
          </Text>
        ))}

        {content.description?.map((cont, index) => (
          <Box key={index}>
            <Heading
              size="md"
              mb={4}
              mt={10}
              bg={colors.primary[50]}
              borderRadius={5}
              width={'fit-content'}
              color={colors.primary[300]}
            >
              {cont.title}
            </Heading>
            <Heading
              size="sm"
              mb={4}
              mt={10}
              bg={colors.primary[50]}
              borderRadius={5}
              width={'fit-content'}
              color={colors.primary[300]}
            >
              {cont.subtitle}
            </Heading>
            
            <Box>
              <Text>{cont.descr}  <span dangerouslySetInnerHTML={{ __html: cont.link }}/></Text>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Content;
