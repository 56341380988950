export const ar = {
  "navbar": {
    "home": "الصفحة الرئيسية",
    "selective_mutism": "الخرس الانتقائي",
    "autism": "مرض التوحّد",
    "aboutUs": "نبذة عن أنفسنا",
    "participate": "المشاركة",
    "kidssmass": "مشروع KIDS-SM-ASS",
    "contactUs": "التواصل",
    "joinIn": "الانضمام",
    "logo": [
      "https://mappy.s3.eu-central-1.amazonaws.com/images/vitlogo.png",
      "https://mappy.s3.eu-central-1.amazonaws.com/images/logopartner.png"
    ]
  },
 "home":{
    "welcomeMessage": "بكم في موقع مشروع KIDS-SM-ASS!",
    "welcomeMessageDescription": "التشخيص التفاضلي المدعوم بالذكاء الاصطناعي في الفحص لاضطراب الصمت الانتقائي واضطراب طيف التوحد",
    "homeDescription": 'مرحبًا بكم في الموقع الإلكتروني لمشروع KIDS-SM-ASS! يرمز مشروع KIDS-SM-ASS إلى التشخيص التفريقي المدعوم بالذكاء الاصطناعي للكشف عمّا يُسمَّى بـ"الخرس الانتقائي" و"اضطرابات طيف التوحد". يمكنكم هنا معرفة المزيد عن ماهية الخرس الانتقائي (SM) واضطرابات طيف التوحد (ASD) وعن كيفية الاستفادة من الذكاء الاصطناعي للتشخيص المبكر. بالإضافة إلى ذلك، يمكنكم أن تكتشفوا في هذا الموقع كيف يمكنكم مساعدتنا في دعم الأطفال الذين يعانون من التوحد والخرس الانتقائي بشكل أفضل',
  "banner_images":[
      "https://mappy.s3.eu-central-1.amazonaws.com/images/bg.png",
      "https://mappy.s3.eu-central-1.amazonaws.com/images/child.svg",
      "https://mappy.s3.eu-central-1.amazonaws.com/images/partners.png",
      "https://mappy.s3.eu-central-1.amazonaws.com/images/ground.png"
    ]
  },
  "selectiveMutism": {
    "title": "الخرس الانتقائي ",
    "question": "ما هو الخرس الانتقائي؟",
    "description": [
      {
        "descr": "الأطفال المصابون بالخرس الانتقائي هم أطفال لا يتحدثون في مواقف معَيَّنة يمكن التنبؤ بها، أو لا يتحدّثون مع أشخاص مُعَيَّنِين، بينما يكون التحدّث في مواقف أخرى، وخاصة مع الأشخاص المألوفين لديهم، ممكنًا دون أي مشاكل."
      },
      {
        "descr": 'يُعتبر "الخرس الانتقائي" أحد اضطرابات القلق ويظهر لدى حوالي 1 بالمائة من جميع الأطفال، مع احتمال كبير أن تكون النسبة الفعلية أعلى من ذلك. غالبًا ما يُفترض بشكل خاطئ أن الطفل ليس إلّا "خجولا بعض الشيء"، ويتم رفض الأهل الباحثين عن المشورة بعبارات مثل "سيتخلص من ذلك مع تقدّم العمر". بخلاف الأطفال الخجولون، والذين يبدؤون عادةً بالتحدث بعد فترة من التأقلم في المواقف الاجتماعية ويصبحون أكثر ارتياحًا مع الوقت، فإن الأطفال المصابين بالخرس الانتقائي يُظهرون استقرارًا زمنيًا كبيرًا في عدم التحدّث.'
      },
      {
        "descr": 'يمكن أن تظهر أعراض الأطفال المصابين بالخرس الانتقائي بدرجات متفاوتة. فبعض الأطفال يتصرفون في المواقف المثيرة للقلق وكأنهم "مُتجَمِّدون". بينما قد يجيب آخرون على الأسئلة بإيماءات أو تعابير وجهية فقط، أو باستخدام عبارات قصيرة ومقتضبة، أو بصوت منخفض جدًا أو حتى بالهمس. '
      },
      {
        "descr": 'قد يتسبّب الخرس الانتقائي في تأثيرات سلبية كبيرة على حياة الأطفال، حيث لا يستطيعون التصرف كما يرغبون بسبب خوفهم. يؤثر ذلك على أدائهم الدراسي وكذلك على تواصلهم مع أقرانهم أو مع طاقم روضة الأطفال أو المدرسة. ولتحديد ما إذا كان الطفل يعاني من الخرس الانتقائي، فإنّه يُوصى بإجراء تشخيص نفسي مفصَّل.'
      },
      {
        "descr": "الخرس الانتقائي هو اضطراب يبدأ عادةً بين عمر سَنَتَيْن وخمس سنوات. وفقًا للدراسات الحالية، فإنّ الخرس الانتقائي ينشأ نتيجة لمجموعة من العوامل المؤثرة، غير أنّه قد تلعب تلك العوامل المختلفة أدوارًا متفاوتةَ الأهمية عند الأطفال المختلفين. يظهر لدى بعض الأطفال مَيل إلى السلوك القلق أو الانطوائي منذ سن الرضع، حيث يستجيبون للمحفزات الجديدة وغير المألوفة بالتوتر والتجنب. إذا أظهر الطفل مثل هذا المزاج القلق، فإن هذا يزيد من احتمالية الإصابة بالخرس الانتقائي في وقت لاحق. أثبتت الدراسات وجود تأثير وراثي في نشوء الخرس الانتقائي. كما يبدو أن وجود خلفية مهاجرة عامِلُ خطر لتطور هذا الاضطراب، حيث يلعب تعلم لغة جديدة وما يرتبط به من عدم الثقة في التحدث دورًا رئيسيًا في ذلك."
      },
      {
        "descr": "لا ينشأ الخرس الانتقائي نتيجةَ تجربة صادمة أو صدمة نفسية تعرَّض لها الطفل."
      },
      {
        "descr": "وفي العادة ليس الخرس الانتقائي عبارة عن ردّ فعل عنيد أو عن الرفض المتعمّد للتحدث. بل يعجز الأطفال المعانون منه عن التحدث بسبب خوفهم الشديد، رغم رغبتهم في التحدث."
      }
    ]
  },
  "autism": {
    "title": "مرض التوحّد",
    "question": "ما هو مرض التوحّد؟ ",
    "description": [
      {
        "descr": "يتميز اضطراب طيف التوحد بصعوبات في التواصل والتفاعل الاجتماعي، بالإضافة إلى سلوكيات تتَّسِم بأنماط متكرّرة وميل للتكرار. يُعتبر التوحّد من اضطرابات النمو ويصاب به حوالي شخص واحد من بين كل مئة شخص. ولكن مظاهر التوحد تختلف بشكل كبير بين الأفراد. يحتاج بعض الأشخاص المصابين بالتوحد إلى دعم في حياتهم اليومية أكثر من غيرهم. وبينما يتمتع البعض بمهارات لغوية جيدة جدًا، لا يستطيع البعض الآخر التحدث أو لا يستطيعون التحدث إلا بشكل محدود."
      },
      {
        "descr":'كثيرا ما يواجه الأشخاص المصابون بالتوحد صعوبات في فهم اللغة أو في تفسير تعابير الوجه أو نبرة الصوت أو الإيماءات التي يستخدمها الآخرون. كما يجدون صعوبة في التعرف على مشاعر الآخرين والتعبير عن مشاعرهم الخاصةكثيرا ما يواجه الأشخاص المصابون بالتوحد صعوبات في فهم اللغة أو في تفسير تعابير الوجه أو نبرة الصوت أو الإيماءات التي يستخدمها الآخرون. كما يجدون صعوبة في التعرف على مشاعر الآخرين والتعبير عن مشاعرهم الخاصة. هذا يجعل التعامل مع المواقف الاجتماعية أمرًا مليئًا بالتحديات بالنسبة لهم.',
      },
      {
        "descr": "التوحد هو اضطراب خِلْقِيٌّ يستمر مدى الحياة، غير أن بعض الأعراض قد تتغير أو تَقِلّ حِدَّتُها بمرور الوقت. بدءًا من بلوغ الطفل عمرَ سنتَيْن يمكن تشخيص التوحد من قبل الأخصائيين (مثل المعالجين/ـات في مجال المعالجة النفسية والأطباء والطبيبات في مجالَيْ الطبّ النفسي والعلاج النفسي)، إذا تخصّصوا في الفئة العمرية المَعْنِيّة. يتم إجراء سلسلة من الفحوصات لهذا الغرض، وهي معقَّدة للغاية وتتضمن مقابلات وملاحظات سلوكية واستبيانات. على الرغم من أن الأعراض الأولى تظهر في مرحلة الطفولة المبكرة، إلا أن بعض الأشخاص لا يتم التعرف على إصابتهم باضطراب طيف التوحد إلا في مرحلة متأخرة جدًا. يمكن أن يكون لهذا أسباب متعددة، مثل عدم التعرف على الأعراض المحددة للتوحد على أنها علامات للتوحد. بالإضافة إلى ذلك، فإن معظم أنظمة الرعاية الصحية مثقلة بالأعباء، مما يؤدي إلى فترات انتظار طويلة للحصول على موعد للتشخيص. "
      }
    ]
  },
  "KIDSSMASS": {
    "title": "مشروع KIDS-SM-ASS ",
    "question": "ماذا نقوم به في إطار مشروع KIDS-SM-ASS؟",
    "introduction": "إن الهدف لمشروع KIDS-SM-ASS هو تطوير أداة فحص والتحقق من تأديته الوظائفية، حيث تهدف أداة فحص هذه إلى تحسين التشخيص التفريقي لاضطرابات طيف التوحد (ASD) والخرس الانتقائي (SM) في مرحلة الطفولة. تحدث حاليًا أخطاء تشخيصية متكررة كما أنّ هناك أوقاتَ انتظار طويلة في العملية التشخيصية. من خلال جمع البيانات التشخيصية أثناء لعبة حاسوبية وتحليلها باستخدام الذكاء الاصطناعي لمعايير مختلفة مثل حركات العين وتعبيرات الوجه والصوت، يمكن تحسين الوضع الحالي، مما يتيح للأطفال المصابين الحصول على تشخيص شامل ودقيق ومعالجة محددة بشكل أسرع.",
    "description": [
      {
        "title": "وصف المشروع",
        "descr": [
          "إن الهدف لمشروع KIDS-SM-ASS هو تطوير أداة فحص والتحقق من تأديته الوظائفية، حيث تهدف أداة فحص هذه إلى تحسين التشخيص التفريقي لاضطرابات طيف التوحد (ASD) والخرس الانتقائي (SM) في مرحلة الطفولة. تحدث حاليًا أخطاء تشخيصية متكررة كما أنّ هناك أوقاتَ انتظار طويلة في العملية التشخيصية. من خلال جمع البيانات التشخيصية أثناء لعبة حاسوبية وتحليلها باستخدام الذكاء الاصطناعي لمعايير مختلفة مثل حركات العين وتعبيرات الوجه والصوت، يمكن تحسين الوضع الحالي، مما يتيح للأطفال المصابين الحصول على تشخيص شامل ودقيق ومعالجة محددة بشكل أسرع."
        ],
        "image": ""
      },
      {
        "title": "ما هي المشكلة التي نريد حلها؟",
        "descr": [
          "في الفحوص التي يُجرِيها الأطباء والطبيبات في مجالَيْ الطبّ النفسي وطبّ الأطفال، تظهر لدى الأطفال المصابين بالتوحد وكذلك لدى الذين يعانون من الخرس الانتقائي، تداخل كبير في الأعراض. على سبيل المثال، قد يُظهر كلاهما في مواقف الفحص سلوكيات مثل عدم الاستجابة للحديث أو تجنب التواصل البصري. وهذا يجعل التمييز بين الاثنين أمرًا صعبًا. العامل الحاسم الذي يمكّننا من التفريق بينهما هو بيئة الطفل المألوفة في المنزل، حيث يُفترض ألا يظهر الأطفال المصابون بالخرس الانتقائي الأعراض المشابهة لأعراض اضطرابات طيف التوحد. من ناحية أخرى، هناك آليات مختلفة تميّز بين الخرس الانتقائي واضطرابات طيف التوحد."
        ],
        "image": ""
      },
      {
        "title": "وكيف نريد حل ذلك؟",
        "descr": [
          "نظرًا إلى ما تقدّم يتمثل نهجنا في نقل إجراء الفحص إلى داخل منزل الطفل. ",
          "يلعب الطفل لعبة قصيرة على جهاز لوحي، وذلك برعاية شخص مألوف لديه. خلال هذه الفترة، يتم جمع بيانات متنوّعة، بما في ذلك حركات العين وتعابير الوجه والصوت وأوقات الاستجابة. يتمّ تحليل البيانات التي تم جمعها تلقائيًا باستخدام الذكاء الاصطناعي، ثم يتمّ إرسال تقرير إلى الأخصائيـ/ـة المسؤولـ/ـة عن المعالجة. تسمح المعايير التي تم جمعها باستخلاص استنتاجات حول خصائص معيّنة. على سبيل المثال يختلف الأطفال المصابون بالتوحد واضطرابات القلق عن بعضهم البعض وعن المجموعات الأخرى في معايير معينة تتعلق بالصوت وأنماط حركة العين."
        ],
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/kidssmass2.png"
      },

      {
        "title": "",
        "descr": [
          "بهذه الطريقة يتمّ تقليل الضغط على الطفل الذي يتم فحصه، كما تهدف إلى مساعدة الكوادر المتخصصة ليس فقط في توفير الوقت، بل كذلك في الحصول على المعلومات التي يُحتاج إليها لاتخاذ أفضل القرارات العلاجية."
        ],
        "image": ""
      },
      {
        "title": "وما هي أهدافنا للمشروع؟",
        "descr": [
          "لقد أكملنا تطوير أنموذج أولي لأداة لفحص التوحد. يحتوي هذا الأنموذج على نماذج ذكاء اصطناعي من أجل تحليل الصوت وتَتَبُّعِ حركة العين وتعابير الوجه. نقوم في إطار مشروعنا بتطوير هذا الأنموذج الأولي واختباره مع الأشخاص الذين من المُفترض أن يكونوا هم المستخدمين له مستقبلاً: الأطفال وأولياء أمورهم. بالإضافة إلى ذلك نقوم بتطوير برنامج لتدريب الآباء والأمهات والذي يهدف إلى تسهيل استخدامه في المنزل، كما نقوم بإجراء دراسة سريرية. خلال هذه الدراسة يتم جمع البيانات مرةً في المختبر وعِدَّةَ مرات في المنزل مِن قِبل الآباء والأمهات. ومن أجل المقارنة يتم إجراء تشخيص نفسي شامل، مِمَّا يسمح بتحديد معايير الجودة للاختبار. تُعَدُّ هذه الدراسة جزءًا من دراسة SMASS حول أوجه التشابه والاختلاف بين الخرس الانتقائي واضطراب طيف التوحد، والتي تُجرى في جامعة يوستوس ليبيغ في غيسن."
        ],
        "image": ""
      }
    ],
    "financialSupportTitle": "الدعم",
    "financialSupportDescription": [
      {
        "descr": "يتم دعم مشروعKIDS-SM-ASS  من قِبل وزارة التعليم والبحث العلمي الاتّحادية الألمانية في إطار برنامج DATIpilot  بصفته ما يُسمّى بـالسباق الابتكاري.",
        "url": "",
        "image":""
      },
      {
        "descr": "أصدرت وزارة التعليم والبحث العلمي الاتّحادية في شهر يوليو 2023 إرشادات تمويل DATIpilot والتي تتكوّن من وحدتين: السباقات الابتكارية (الوحدة 1) ومجتمعات الابتكار (الوحدة 2). يهدف برنامج DATIpilot إلى تبسيط عمليات التمويل وتسريعها. بالإضافة إلى ذلك يمكن اعتبار البرنامج فضاءً تجريبيًّا ومستودعًا للخبرات والأفكار من أجل الوكالة الألمانية للنقل والابتكار (DATI) التي يجري إنشاؤها حاليًا.",
        "image": "",
        "url": ""
      },
      {
        "descr": "يتم تمويل ما مجموعه 300 سباق ابتكاري. ومن خلال الجولة التعريفية لبرنامج DATIpilot بين شهرَيْ نوفمبر 2023 وفبراير 2024، قام حوالي 600 مشارك فردي أو فريق بتقديم أفكارهم من خلال عروض تقديمية مدتها خمس دقائق. وفي كل من الفعاليات الـ23 قام المشاركون أنفسهم باختيار المشاريع التي تحصل على التمويل. وبهذه الطريقة قد حصلتْ إلى الآن 153 سباقًا ابتكاريًا على تمويل. أما المشاركون الآخرون فقد أُعطُوا فرصة ثانية من خلال السحب العشوائي، حيث تم اختيار 147 مشروعًا إضافيًا.",
        "image": "",
        "url": ""
      },
      {
        "title": "",
        "descr": "تخصّ تلك المشاريعُ مجالات موضوعية متنوعة. تتركز أكثر المشاريع في مجالات الذكاء الاصطناعي/التعلم الآلي والتقنيات الطبية/الصيدلانية وقطاعَيْ الصحة والخدمات الاجتماعية، بينما تتعلق حوالي 20 بالمائة من المشاريع بـالابتكارات الاجتماعية.",
        "image": "",
        "url": ""
      },
      {
        "descr": "يمكنكم زيارة الرابط التالي للحصول على المزيد من المعلومات:",
        "image": "",
        "url": "www.bmbf.de/datipilot"
      },
      {
        "descr": 'وبالمناسبة، تم توثيق جميع المشاريع التي تمّ تقديمها كـ"تسجيلات بيانية (Graphic Recordings)". قامت أنيا ريسه بإعداد هذا التسجيل البياني الرائع لعرض مشروع KIDS-SM-ASS. ',
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/descr.jpg",
        "url": "www.bmbf.de/datipilot"
      }
    ]
  },

  "about": {
    "title": "نبذة عن أنفسنا ",
    "question": "من نحن؟ ",
    "description": {
      "descr": "يعمل في مشروع KIDS-SM-ASS علماء وعالمات من جامعة يوستوس ليبيغ في غيسن (JLU) بالتعاون مع شركة Vitafluence.ai من مدينة كرونبرغ. تقوم بإدارة المشروع من جانب جامعة يوستوس ليبيغ السيدة أ. د. كريستينا شفنك، ومن جانب Vitafluence.ai السيدة د. وامويو أووتوكي."
    },
    "ourTeam": [
      {
        "name": "السيدة أ. د. كريستينا شفنك",
        "title": "جامعة يوستوس ليبيغ في غيسن",
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/christina.png",
        "descr": [
          "السيدة كريستينا شفنك هي أستاذة جامعية لعلم النفس السريري للأطفال والمراهقين في جامعة يوستوس ليبيغ في غيسن، وتُعتبر خبيرة دولية معترف بها في مجال الخرس الانتقائي. درست علم النفس في جامعتي فورتسبورغ ومدريد، وحصلت على درجة الدكتوراه في علم النفس التنموي من جامعة فورتسبورغ. عملت في أقسام الطب النفسي للأطفال والمراهقين والطب النفسي الجسدي والعلاج النفسي في المستشفيات الجامعية في فورتسبورغ وميونيخ وفرانكفورت. بالإضافة إلى ذلك فإنّها معالِجة نفسية معتمدة للأطفال والمراهقين (العلاج السلوكي) ومشرفة معتمدة. تشمل اهتماماتها البحثية الخرس الانتقائي واضطرابات السلوك والأطفال الذين يعاني آباؤهم أو أمهاتهم من أمراض نفسية.",
          "تُساهم أ. د. كريستينا شفنك في تعزيز الصحة النفسية للأطفال على مستوى العالم، وذلك من خلال دورها في الرابطة الدولية لطب نفس الأطفال والمراهقين والمهن المرتبطة بها (IACAPAP). شغلت منصب الأمينة العامة للرابطة من عام 2018 حتى 2022 وتشغل حاليًا منصب منسقة ندوة هيلموت ريمشميت البحثية (HRRS) ضمن اللجنة التنفيذية للرابطة. ",
          "وفي أوقات فراغها تتابع شغفها بالتصوير الفوتوغرافي حول العالم."
        ]
      },
      {
        "name": "السيدة د. وامويو أووتوكي",
        "title": "شركة Vitafluence.ai",
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/wamuyu.png",
        "descr": [
          'الدكتورة وامويو أووتوكي هي مؤسسة مشاركة والمديرة العلمية لشركة Vitafluence.ai، وكذلك مؤسسة مشاركة والمديرة التنفيذية لمنصة iZola.life. هناك، تعمل على تطوير ابتكارات في مجالات التطبيب عن بُعد، والفحص القائم على الذكاء الاصطناعي، وطرق تطوير الأدوية المدعومة بالذكاء الاصطناعي. درست الصيدلة في سانت بطرسبرغ وحصلت على درجة الدكتوراه في الكيمياء الطبية في هامبورغ. بالإضافة إلى ذلك، تمتلك خبرة تمتد إلى 12 عامًا في صناعة الأدوية في مجالات علم الأعصاب وطب العيون وعلاج الأورام. إلى جانب مسؤولياتها اليومية كمؤسسة مشاركة، تلعب أحيانًا دور "تاكسي الأم"، وتشارك في المبادرات الخيرية التطوعية، وتدعم مشهد الشركات الناشئة من خلال إلهام النساء وتشجيعهن على الدخول في مجال ريادة الأعمال. '
        ]
      },
      {
        "name": "ألكسندرا نتيسو، صاحبة درجة الماجستير في العلوم",
        "title": "جامعة يوستوس ليبيغ في غيسن",
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/alexandra.png",
        "descr": [
          "تعمل ألكسندرا نيتسو كمعاونة بحثية في قسم علم النفس السريري للأطفال والمراهقين (تحت إشراف أ. د. كريستينا شفنك) في جامعة يوستوس ليبيغ في غيسن. تركز أبحاثها على دراسة أوجه التشابه والاختلاف بين الخرس الانتقائي واضطراب طيف التوحد. حصلت على درجة البكالوريوس في علم النفس من جامعة ترير، ودرجة الماجستير من جامعة كمنيتس التقنية، وحاليًا تتابع دراساتها لنيل درجة الدكتوراه في جامعة يوستوس ليبيغ في غيسن. في أوقات فراغها، تستمتع بالقراءة والمشي الطويل في الطبيعة."
        ]
      },
      
      {
        "name": "أنينكا إنسيروث، صاحبة درجة الماجستير في العلوم ",
        "company": "شركة Vitafluence.ai ",
        "title": "Vitafluence.ai",
        "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/anninka.png",
        "descr": [
          "تعمل أنينكا إنسيروث كأخصائية نفسية لدى شركة Vitafluence.ai، حيث تقوم بتطوير ودراسة أساليب تعتمد على الذكاء الاصطناعي للكشف عن اضطراب التوحد والاضطرابات ذات الصلة. درست علم النفس في برلين والقاهرة وغيسن وحصلت على درجة الماجستير من جامعة يوستوس ليبيغ من خلال مشروع بحثي لتقييم العلاج داخل المستشفى لاضطرابات ما بعد الصدمة. تحبّ الكلاب والقهوة بالهيل والبودكاستات عن تاريخ العصور الوسطى، والتي تفضل الاستماع إليها أثناء جودها في صالة الألعاب الرياضية."
        ]
      }
    ],
  },

  "participate": {
    "title": "كيف يمكنني المشاركة؟",
    "join": [{
      "title": "",
      "question": "كيف يمكنني المشاركة؟",
      "joinDescription": "نسعد برغبتكم في المشاركة! يمكنكم المشاركة إذا كنتم تبلغون من العمر 18 عامًا على الأقل و",
      "instructions": [
        {
          "descr": "كنتم الوالدين المسؤولين عن رعاية طفل يتراوح عمره بين 4 و12 عامًا و:",
          "instructr": [
            "تمّ تشخيصه (أو هناك اشتباه بتشخيصه) بالخرس الانتقائي، أو",
            "تم تشخيصه (أو هناك اشتباه بتشخيصه) باضطراب طيف التوحد، أو",
            "هو خالٍ عن أي اضطراب نفسي."
          ]
        }
      ]
    }],
    "getInvolved": [
      {
        "title": "هناك عدّة طرق للمشاركة:",
        "instructions": [
          "المشاركة في الجزء الإلكتروني من SMASS  .1",
          "المشاركة كـمختبر/ـة تجريبيـ/ـة للنسخة التجريبية (بيتا) لـNeurobee .2",
          "المشاركة في الجزء المختبري وفي Neurobee .3 "
        ],
        "how": [
          {
            "title": "الجزء الإلكتروني من SMASS",
            "descr": [
              {
                "descr": "نقوم في إطار دراستنا بتحليل أوجه التشابه والاختلاف بين حالتين نفسيتين، وهما الخرس الانتقائي واضطراب طيف التوحد، باستخدام مجموعة من الاستبيانات المختلفة. يُعد هذا مهمًا جدًا ليس فقط لتحسين التشخيص، بل كذلك لتقديم الدعم العلاجي الأمثل للأطفال المصابين. كما نرغب في استطلاع آراء الآباء والأمهات لأطفال لا يعانون من اضطرابات نفسية، وذلك لمعرفة الفروقات بين الأطفال المصابين بالخرس الانتقائي، واضطراب طيف التوحد، والأطفال الذين لا يعانون من تلك الحالات. تتكون الدراسة من ستة استبيانات (والتي يُحتاج إلى حوالي 40 دقيقة لإكمالها) ويتم تعبئتها عبر الإنترنت. بعد المشاركة يمكنكم الانضمام إلى سحب الجوائز. سنوزع ما مجموعه 20 قسيمة بقيمة 20 يورو. نسعد بمشاركتكم ودعمكم!",
                "link": ""
              }
            ],
            "moreInfo": [
              {
                "descr": "لمزيد من المعلومات، يرجى زيارة الرابط التالي",
                "bold": "",
                "link": ": معلومات المشاركين - استبيان الوالدين",
                "image": ""
              },
              {
                "descr": "في حال وجود أي أسئلة فإنّه يرجى التواصل عبر البريد الإلكتروني على العنوان التالي:",
                "bold": "",
                "link": "StudieSMASS@psychol.uni-giessen.de",
                "image": ""
              },
              {
                "descr": "يمكنكم الوصول إلى الجزء الأول من الدراسة من خلال رمز الإجابة السريعة أو بالنقر على الرابط التالي:",
                "bold": "وستجدون مسبقًا معلومات مفصلة حول خلفية الدراسة والإجراءات المتبعة فيها وحول حماية البيانات. ",
                "link": "https://www.soscisurvey.de/SMASS/",
                "image": "https://mappy.s3.eu-central-1.amazonaws.com/images/enqr.png"
              },
              {
                "descr": "نبحث عمن يرغب في المشاركة في الجزء الإلكتروني من دراسة SMASS بدءًا من الآن.",
                "bold": "ab sofort.",
                "link": "",
                "image": ""
              },
              {
                "descr": "يرجى الانتباه:",
                "bold": "تنبيه: بعد المشاركة في الجزء الإلكتروني، يمكنكم بالطبع المشاركة أيضًا في الجزء المختبري وNeurobee، غير أنّه ليس إلزاميًا.",
                "link": "",
                "image": ""
              }
            ]
          },
          {
            "title": "المشاركة في الاختبار التجريبي للنسخة التجريبية (بيتا) لـNeurobee ",
            "descr": [
              {
                "descr": 'ممّا نهتمّ به خلال تطوير تطبيق Neurobee، هو معرفة ما إذا كانت اللعبة ممتعة للأطفال في هذه الفئة العمرية، ومعرفة مدى سهولة تنفيذها من قبل الآباء والأمهات. نظرا إلى ذلك فإنّا نبحث عن أناس يقومون بالاختبار التجريبي (اختبار نسخة بيتا). في هذا الإطار نوفر لكم تطبيق Neurobee وجهاز لوحي لتجربة اللعبة في المنزل مع طفلكم. بعد الانتهاء من اللعبة سنطلب منكم ومن طفلكم تقديم ملاحظاتكم حول جوانب مختلفة من اللعبة، مثل طولها وتصميمها ومدى سهولة تنفيذها. نبحث عمّن يقوم بالاختبار التجريبي خلال نوفمبر 2024. للحصول على مزيد من المعلومات أو للتسجيل في اختبار النسخة التجريبية، يرجى مراسلتنا عبر البريد الإلكتروني التالي: neurobee@vitafluence.ai أو التواصل معنا عبر نموذج الاتصال تحت قسم "المشاركة (Mitmachen)".',
                "link":"<b>neurobee@vitafluence.ai.</b>"
              }
            ],
            "moreInfo": []
          },
          {
            "title": "الجزء المختبري وتطبيق Neurobee",
            "descr": [
              {
                "descr": "إذا شاركتم في الجزء الإلكتروني لدراسة SMASS وكنتم من منطقة غيسن، فإنّه يمكنكم أيضًا المشاركة في الجزء الثاني من الدراسة (الجزء المختبري وتطبيق Neurobee). في الجزء المختبري ستقومون مع طفلكم بتنفيذ مهامّ مختلفة، منها اختبار ذكاء ومهام تفاعلية (ألعاب) معكم بالإضافة إلى مهام تفاعلية (ألعاب) مع القائمين على التجربة. بعد ذلك سيقوم طفلكم بإجراء اختبار تشخيصي يسمى Neurobee أربع مرات (مرة واحدة في المختبر وثلاث مرات في المنزل) تحت إشرافكم. تطبيق Neurobee هو لعبة كمبيوتر يتم خلالها جمع وتحليل بيانات تشخيصية مثل حركات العين وتعابير الوجه وترددات الصوت. تُعد النتائج ذات أهمية كبيرة للتشخيص كما أنها تلعب دورًا هامًا في تقديم الدعم العلاجي الأمثل للأطفال المصابين. كمكافأة على مشاركتكم ستحصلون على قسيمة بقيمة 160 يورو، كما سيحصل طفلكم على هدية صغيرة. تجدون مزيدا من المعلومات هنا:",
                "link": "معلومات المشاركين - الوالدين، الجزء المختبري وتطبيق Neurobee"
              },
              {
                "descr": "نبحث عن مشاركين في الجزء المختبري لدراسة SMASS مع Neurobee ابتداءً من",
                "bold": "شهر ديسمبر 2024"
              }
            ],
            "moreInfo": []
          }
        ]
      }
    ]
  },

  "contact": {
    "title": "التواصل",
    "contactUs": [
      {
        "question": "ماذا أفعل إذا كان لدي المزيد من الأسئلة؟",
        "description": "إذا كانت لديكم أسئلة إضافية، يمكنكم التواصل معنا عبر نموذج الاتصال على هذا الموقع أو عبر البريد الإلكتروني.",
        "contact":"<ul style='margin-left: 30px'><li>جامعة يوستوس ليبيغ:  <b>StudieSMASS@psychol.uni-giessen.de.</b></li> <li>شركة Vitafluence.ai:  <b>neurobee@vitafluence.ai</b> </li></ul>",

      }
    ]
  },
  "faqs": {
    "title": "Frequently Asked Questions",
    "questions": [
      {
        "question": "What is selective mutism?",
        "answer": "Selective mutism is a complex anxiety disorder that affects a person's ability to speak in certain social situations."
      },
      {
        "question": "How can I help someone with selective mutism?",
        "answer": "Helping someone with Selective Mutism means creating a supportive environment and gradually encouraging them to speak comfortably."
      }
    ]
  },
  "study": [
    "مختبرو بيتا لـ Neurobee (أكتوبر 2024 – نوفمبر 2024)",
    "دراسة SMASS عبر الإنترنت والمختبر (أكتوبر 2024 – سبتمبر 2025)",
    "لست متأكدًا بعد"
  ],
  "join_study": {
    "phone_number": "رقم الهاتف",
    "address": "عنوان البريد الإلكتروني",
    "select_study": "حدد الدراسة التي تهتم بها",
    "name": "الاسم الكامل",
    "nameLabel": "ما هو اسمك الكامل؟",
    "emailLabel": "ما هو بريدك الإلكتروني؟",
    "phoneLabel": "ما هو رقم هاتفك (اختياري)؟",
    "studyOptionLabel": "أي جزء من الدراسة تهتم به؟ (يمكن اختيار أكثر من خيار)",
    "descriptionLabel": "هل هناك أي شيء آخر تريد إخبارنا به؟",
    "privacy_policy": "لقد قرأت وأوافق على",
    "dePrivacyExt": "",
    "policy": "سياسة الخصوصية",
    "description": "هل هناك أي شيء آخر تريد إخبارنا به؟ (اختياري)",
    "joinInTitle": "يرجى ملء النموذج أدناه للانضمام",
    "join": "انضم",
    "policy_check_warning": "يرجى تحديد مربع سياسة الخصوصية",
    "input_warning": "يرجى ملء الحقول المطلوبة",
    "error_submiting_email": "حدث خطأ أثناء إضافة بريدك الإلكتروني. يرجى المحاولة مرة أخرى",
    "email_sent": "تم إرسال الطلب بنجاح، تحقق من بريدك الإلكتروني لمزيد من التفاصيل!",
    "cancel": "إلغاء",
    "joining": "جارٍ الانضمام"
  },

  "impressum": {
    "title":"Impressum",
    "address": "Vitafluence.ai GmbH Westerbachstraße 23 61476 Kronberg",
    "contacts": [
      "Telefon: +49 15560676362",
      "E-Mail: neurobee@vitafluence.ai."
    ],
    "description":[
      {
        "title":"Vertreten durch:",
        "subtitle": "",
        "descr": "Geschäftsführer Dr. Peter O. Owotoki Registereintrag:"
        ,"link": ""
      },
      {
        "title":"Registereintrag:",
        "subtitle": "",
        "descr": "Eingetragen im Handelsregister. Registergericht: Amtsgericht Königstein im Taunus Registernummer: HRB 10044",
        "link": ""
      },
      {
        "title":"Umsatzsteuer-ID:",
        "subtitle": "",
        "descr": "Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz: 043 240 96517",
        "link": ""
      },
      {
        "title":"Hinweis gemäß Online-Streitbeilegungs-Verordnung",
        "subtitle": "",
        "descr": "Nach geltendem Recht sind wir verpflichtet, Verbraucher auf die Existenz der Europäischen Online-Streitbeilegungs-Plattform hinzuweisen, die für die Beilegung von Streitigkeiten genutzt werden kann, ohne dass ein Gericht eingeschaltet werden muss. Für die Einrichtung der Plattform ist die Europäische Kommission zuständig. Die Europäische Online-Streitbeilegungs-Plattform ist hier zu finden:",
        "link": "<a href='http://ec.europa.eu/odr'>http://ec.europa.eu/odr</a> Unsere E-Mail lautet: <a href='mailto:neurobee@vitafluence.ai>neurobee@vitafluence.ai.</a>"
      },

      {
        "title":"",
        "subtitle": "",
        "descr": "Wir weisen aber darauf hin, dass wir nicht bereit sind, uns am Streitbeilegungsverfahren im Rahmen der Europäischen Online-Streitbeilegungs-Plattform zu beteiligen. Nutzen Sie zur Kontaktaufnahme bitte unsere obige E-Mail und Telefonnummer."
      ,"link": ""
      },
      {
        "title":"Disclaimer – rechtliche Hinweise",
        "subtitle": "§ 1 Warnhinweis zu Inhalten",
        "descr": "Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt. Der Anbieter dieser Webseite übernimmt jedoch keine Gewähr für die Richtigkeit und Aktualität der bereitgestellten kostenlosen und frei zugänglichen journalistischen Ratgeber und Nachrichten. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder. Allein durch den Aufruf der kostenlosen und frei zugänglichen Inhalte kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen des Anbieters."
      ,"link": ""
      },
      {
        "title":"",
        "subtitle": "§ 2 Externe Links",
        "descr": "Diese Website enthält Verknüpfungen zu Websites Dritter ('externe Links'). Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht."
      ,"link": ""
      },
      {
        "title":"",
        "subtitle": "§ 3 Urheber- und Leistungsschutzrechte",
        "descr": "Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt."
      ,"link": ""
      },
      {
        "title":"",
        "subtitle": "",
        "descr": "Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig."
      ,"link": ""
      },
      {
        "title":"",
        "subtitle": "§ 4 Besondere Nutzungsbedingungen",
        "descr": "Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Paragraphen abweichen, wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen. Quelle:",
        "link":"<a styles='color: #4FBA64' href='https://www.juraforum.de/'>Hier klicken </a>"
      }
    ]
  },
  "privacy_policy": {
    "title":"Privacy policy",
    "description":[
      {
        "title":"Introduction",
        "subtitle": "",
        "descr": "Thank you for choosing to be part of our community at Vitafluence.ai. We are committed to protecting your personal information and right to privacy governed by the principles of General Data Protection Regulation (GDPR). If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at "
        ,"link": "<b> neurobee@vitafluence.ai.</b>"
      },
      {
        "title":"",
        "subtitle": "",
        "descr": "When you visit our website, use our mobile application or any of our services, we appreciate that you are trusting us with your personal information. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. Please take some time to read though it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please inform us immediately.",
        "link": ""
      },
      {
        "title":"What data do we collect?",
        "subtitle": "",
        "descr": "Vitafluence.ai collects personal data when you submit an inquiry through our contact form for the purpose of contacting you about participation in our research. Upon your request and expression of consent, your data might also be shared with third parties such as our research partners. It is removed upon your withdrawal of consent or your request to delete your data.",
        "link": "<ul style='margin-left: 30px'><li>Personal identification information (Name, email address, phone number etc.)</li><li>The content of your message</li></ul>"
      },
      {
        "title":"",
        "subtitle": "",
        "descr": "If you leave a message on the Website, your name and email address will also be saved in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will be saved on your computer until you delete them",
        "link": "<ul style='margin-left: 30px'><li><b>IP and browser user agent string:</b> this data is collected when you leave a comment.</li> <li><b>Retention period:</b> the aforementioned data is retained indefinitely so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue. </li></ul>"
      },

      {
        "title":"How do we collect your data?",
        "subtitle": "",
        "descr": "You directly provide Vitafluence.ai with most of the data we collect. We collect data and process data when you:",
        "link": "<ul style='margin-left: 30px'><li>Send a message through our contact form</li><li>Vitafluence.ai may also receive your data indirectly from your browser as inputs to cookies.</li></ul>"
      },
      {
        "title":"How do we store your data?",
        "subtitle": "",
        "descr": "itafluence.ai securely stores your data at our cloud data storage facilities hosted in the cloud in region Frankfurt. We strictly adhere to the GDPR principle of storage limitation and data retention policies; therefore, we erase or anonymize any personal data that is no longer needed. A client also has the right to revoke access and request us to delete all their personal data at any given time."
      ,"link": ""
      },
      {
        "title":"Marketing",
        "subtitle": "",
        "descr": "Vitafluence.ai would like to send you information about products and services of ours that we think you might like, as well as those of our partners."
      ,"link": ""
      },
      {
        "title":"",
        "subtitle": "",
        "descr": "If you have agreed to receive marketing, you may always opt out at a later date. You have the right at any time to stop Vitafluence.ai from contacting you for marketing purposes or giving your data to other members of the Vitafluence.ai Company. If you no longer wish to be contacted for marketing purposes, you will be able to make the changes in your profile on our website."
      ,"link": ""
      },
      {
        "title":"What are your data protection rights?",
        "subtitle": "",
        "descr": "Vitafluence.ai would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:."
      ,"link": "<ul style='margin-left: 30px'><li><b>The right to access</b> - You have the right to request Vitafluence.ai for copies of your personal data.</li><li><b>The right to rectification</b> - You have the right to request that Vitafluence.ai correct any information you believe is inaccurate. You also have the right to request Vitafluence.ai to complete the information you believe is incomplete.</li><li><b>The right to erasure </b>- You have the right to request that Vitafluence.ai erase your personal data, under certain conditions.</li><li><b>The right to rectification</b> - You have the right to request that Vitafluence.ai correct any information you believe is inaccurate. You also have the right to request Vitafluence.ai to complete the information you believe is incomplete.</li><li><b>The right to erasure </b> - You have the right to request that Vitafluence.ai erase your personal data, under certain conditions. </li><li><b>The right to restrict processing </b> - You have the right to request that Vitafluence.ai restrict the processing of your personal data, under certain conditions.       </li><li><b>The right to object to processing </b> - You have the right to object to Vitafluence.ai’s processing of your personal data, under certain conditions. </li><li><b>The right to data portability </b> - You have the right to request that Vitafluence.ai transfer the data that we have collected to another organization, or directly to you, under certain conditions. </li></ul>"
      },
      {
        "title":"",
        "subtitle": "",
        "descr": "If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email:",
        "link":"<b style='color: #7ce0f9'>neurobee@vitafluence.ai.</b>"
      },

      {
        "title":"Cookies",
        "subtitle": "",
        "descr": "Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology For further information, visit.",
        "link":"<a style='color: #7ce0f9' href='https://www.allaboutcookies.org/'> allaboutcookies.org</a>"
      },
      {
        "title":"How do we use cookies?",
        "subtitle": "",
        "descr": "Vitafluence.ai uses cookies in a range of ways to improve your experience on our website, including:",
        "link":"<ul style='margin-left: 30px'><li>Understanding how you use our website.</li></ul>"
      },
      {
        "title":"What types of cookies do we use?",
        "subtitle": "",
        "descr": "There are a number of different types of cookies, however, our website uses:",
        "link":"<ul style='margin-left: 30px'><li><b>Functionality</b> - Vitafluence.ai uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.</li><li><b>User Behaviour  </b>Vitafluence.ai uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address.</li></ul>"
      },

      {
        "title":"How to manage cookies.?",
        "subtitle": "",
        "descr": "You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.",
        "link":""
      },
      {
        "title":"Privacy policies of other websites.",
        "subtitle": "",
        "descr": "Our site may contain links to and from third party websites. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.",
        "link":""
      },
      {
        "title":"Changes to our privacy policy.?",
        "subtitle": "",
        "descr": "Vitafluence.ai keeps its privacy policy under regular review, any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by e-mail. Please check back frequently to see any updates or changes to our privacy policy. This privacy policy was last updated on 22 October 2024.",
        "link":""
      },
      {
        "title":"How to contact us.",
        "subtitle": "",
        "descr": "If you have any questions about Vitafluence.ai’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.",
        "link":"<ul style='margin-left: 30px'><li>Call us: <b>+49 15560676362</b> </li><li>Or write to us at: <b>neurobee@vitafluence.ai.</b></li></ul>"

      },
      {
        "title":"How to contact the appropriate authority.",
        "subtitle": "",
        "descr": "Should you wish to report a complaint or if you feel that Vitafluence.ai has not addressed your concern in a satisfactory manner, you may contact the Data Commissioner’s Office.",
        "link":"<p style='margin-top:20px'>Der Hessische Beauftragte für Datenschutz und Informationsfreiheit <br/>Postfach 3163, 65021 Wiesbaden <br/>poststelle@datenschutz.hessen.de <br/> Telefon: <b>0611-140 80</b></p>"
      }
    ]
  },

  // footer content
  "linkedIn": "لينكدإن",
  "contacts": "جهات الاتصال",
  "company": "الشركة",
  "others": "أخرى",
  "support": "المساعدة والدعم",
  "faq": "الأسئلة الشائعة",
  "impressum_tab": "إفصاح قانوني",
  "copyright": "© 2024 مشروع KIDS-SM-ASS"

}

