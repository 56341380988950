// DirectionContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const DirectionContext = createContext();

export const useDirection = () => {
  return useContext(DirectionContext);
};

export const DirectionProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [direction, setDirection] = useState("ltr");

  // Update direction whenever language changes
  useEffect(() => {
    const lang = i18n.language || localStorage.getItem("appLanguage") || "en";
    if (lang === "ar") {
      document.documentElement.dir = "rtl";
      setDirection("rtl");
    } else {
      document.documentElement.dir = "ltr";
      setDirection("ltr");
    }
  }, [i18n.language]);

  return (
    <DirectionContext.Provider value={{ direction, setDirection }}>
      {children}
    </DirectionContext.Provider>
  );
};
