import React, {useEffect} from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDirection } from "../../context/DirectionContext";

const Content = ({ content }) => {
  const { t } = useTranslation();
  const { direction } = useDirection();
  const location = useLocation();

  // Automatically scroll to the "contactus" section if it's in the URL
  useEffect(() => {
    if (location.pathname === "/selective-mutism") {
      const contactElement = document.getElementById("selectivemutism");
      if (contactElement) {
        contactElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <Box
      textAlign={direction === "rtl" ? "right" :"center"}
      position="relative"
      width="100%"
      id="selectivemutism"
      pt={5}
    >
    
      <Box
        pl={direction === "rtl" ? 0 : "10%"}
        pr={direction === "rtl" ? "10%" : 0}
        height="auto"
        width="90%"
        pt={5}
        backgroundColor={"transparent"}
      // mt={"-10vh"}
      >

        <Heading size="lg" mb={4} textStyle="Title" textAlign={direction === "rtl" ? "right" :'left'} mt={5}>
          {content.title}
        </Heading>
        {content.description?.map((cont, index) => (
          <Text
            key={index}
            textAlign={direction === "rtl" ? "right" : "left"}
            fontSize="md"
            color="gray.700"
            mb={4}
          >
            {cont.descr}
          </Text>
        ))}
      </Box>

      <VStack
        align="left"
        mt={10}
        backgroundSize={"cover"}
        width="100%"
        backgroundRepeat={"no-repeat"}
        backgroundColor={"transparent"}
        zIndex={"1000"}
      >
        <Box width="90%">
          {/* <Image
                src="slide.png"
                alt="slide"
                width="100%"
                objectFit="cover"
                borderRadius="md"
            /> */}
        </Box>
      </VStack>
    </Box>
  );
};

export default Content;
