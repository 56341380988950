import React from "react";
import {
  Box,
  Heading,
  Image,
  Text,
  useTheme,
  Link,
} from "@chakra-ui/react";
import { useDirection } from "../../context/DirectionContext";

const Content = ({ content }) => {
const { colors } = useTheme();
const { direction } = useDirection();
  return (
    <Box
      textAlign={direction === "rtl" ? "right" : "left"}
      position="relative"
      width="100%"
      pt={5}
      backgroundColor={"transparent"}
      justifyContent={"center"}
    >
      <Box
        height="auto"
        width={{ base: "90%", md: "80%", lg: "80%" }}
        backgroundColor={"transparent"}
        justifyContent={"center"}
        margin={"0 auto"}
        pt={5}
      >
        <Heading size="lg" mb={4} bg={colors.primary[200]} textStyle="Title">
          {content.title}
        </Heading>
        <Text
          textAlign={direction === "rtl" ? "right" : "left"}
          fontSize="md"
          color="gray.700"
          mb={4}
        >
          {content.introduction}
        </Text>
        
        {content.description?.map((item, index) => (
          <Box key={item.title}>
            {item.title !== "" && (
              <Heading
                as="h4"
                fontSize="lg"
                width={"fit-content"}
                bg={index === 0 ? colors.primary[100] : index === 1 ? colors.secondary[100] : index === 2 ? colors.accent[100]: colors.green[100] }
                pl={4}
                pr={4}
                pt={2}
                pb={2}
                color={colors.primary[300]}
                borderRadius={'xl'}
                mb={5}
              >
                {item.title}
              </Heading>
            )}
            {item.descr.map((d) => (
              <Text
                key={index}
                textAlign={direction === "rtl" ? "right" : "left"}
                fontSize="md"
                color="gray.700"
                mb={4}
                // ml={(item.title !== "" || (item.title === "" &&d !== content.title))  ? 4: 0}
              >
                {d}
              </Text>
            ))}
            {item.image !== "" && (
              <Image
                src={`${item.image}`}
                alt="item.image"
                width={item.image.includes("descr")?"40%": "60%"}
                objectFit="contain"
                borderRadius="md"
                // ml={4}
                mb={4}
              />
            )}
          </Box>
        ))}

        <Heading size="lg" mb={4} bg={colors.primary[200]} textStyle="Title">
          {content.financialSupportTitle}
        </Heading>

        {content.financialSupportDescription?.map((item) => (
          <Box  key={item.desc}>
            <Text
              textAlign={direction === "rtl" ? "right" : "left"}
              fontSize="md"
              color="gray.700"
              mb={4}
            >
              {item.descr} <Link color={colors.primary[300]} href={`${item.url}`}>{item.url}</Link>
            </Text>

            {item.image !== "" && (
              <Image
                src={`${item.image}`}
                alt="slide"
                width="40%"
                objectFit="cover"
                borderRadius="md"
              />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Content;
