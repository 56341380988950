import React from 'react';
import { VStack, Text } from '@chakra-ui/react';
import HeroSection from '../components/common/HeroSection';
import Content from '../components/participate/Content';
import { useTranslation } from "react-i18next";
import MiniNavbar  from '../components/common/MiniNavbar'; 
const AboutUs = () => {
    const { t } = useTranslation();
    const content = t('participate', { returnObjects: true });
    const contact = t("contact", { returnObjects: true })

    return (
        <VStack spacing={0}>
            <HeroSection />
            <MiniNavbar /> 
            <Content content={content} contact={contact}/>
        </VStack>
    );
};

export default AboutUs;
