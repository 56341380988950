import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en } from './translations/en';
import { de } from './translations/de';
import { ar } from './translations/ar';
import { pl } from './translations/pl';

const extractKeys = (obj, prefix = '') => {
  let keys = [];
  for (const [key, value] of Object.entries(obj)) {
    const newKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === 'object' && value !== null) {
      keys = keys.concat(extractKeys(value, newKey));
    } else {
      keys.push(newKey);
    }
  }
  return keys;
};

const findMissingTranslations = (baseLang, lang, baseLangName, langName) => {
  const baseKeys = extractKeys(baseLang);
  const langKeys = extractKeys(lang);

  const missingKeys = baseKeys.filter(key => !langKeys.includes(key));
  if (missingKeys.length > 0) {
    console.log(`Missing translations in ${langName} compared to ${baseLangName}:`);
    missingKeys.forEach(key => console.log(`- ${key}`));
  } else {
    console.log(`No missing translations in ${langName} compared to ${baseLangName}.`);
  }
};

// Detect missing translations during development
if (process.env.NODE_ENV === 'development') {
  const languages = [
    { name: 'German', translations: de },
    { name: 'Arabic', translations: ar },
    { name: 'Polish', translations: pl },
  ];

  languages.forEach(({ name, translations }) => {
    findMissingTranslations(en, translations, 'English', name);
  });
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: { translation: en },
      de: { translation: de },
      ar: { translation: ar },
      pl: { translation: pl },
    },
    fallbackLng: 'en',
    detection: {
      order: ['path', 'cookie', 'htmlTag', 'localStorage', 'subdomain'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false, // React already escapes values to prevent XSS
    },
  });

export default i18n;
