import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, useMediaQuery, Link as ChakraLink, useColorModeValue } from '@chakra-ui/react';
import { Link as ReactRouterLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import JoinCommunity from './JoinCommunity';

const MiniNav = () => {
  const [isSticky, setSticky] = useState(false);
  const [isMobileView] = useMediaQuery("(max-width: 991px)");
  const { t } = useTranslation();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const heroSectionHeight = document.getElementById('heroSection')?.offsetHeight || 0;
      setSticky(window.scrollY > heroSectionHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const linkBackground = useColorModeValue('primary.200', 'secondary.200');
  const activeLinkStyle = {
    bg: linkBackground,
    bgGradient: "linear(to-b, primary.300, secondary.200)",
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleOpenJoinCommunity = () => {
    setIsOpen(true);
  };

  if (isMobileView) {
    return (
      <Box position={isSticky ? "fixed" : "relative"} top={isSticky ? "60px" : "0"} bg="white" zIndex={10} boxShadow={isSticky ? "md" : "none"} py={2} width={"100%"}>
        <Flex flexDirection={'row'} justifyContent={'center'}>
        
          <Button variant={'outline'} m={1} size="sm" borderStyle={'dashed'} borderColor={"primary.300"} {...(location.pathname === "/kids-sm-ass" ? activeLinkStyle : {})}>
            <ChakraLink fontSize={'sm'} as={ReactRouterLink} to="/kids-sm-ass" color={'gray.600'}>
              {t("navbar.kidssmass")}
            </ChakraLink>
          </Button>
          <Button variant={'outline'} m={1} size="sm" borderStyle={'dashed'} borderColor={"primary.300"} {...(isOpen ? activeLinkStyle : {})} onClick={handleOpenJoinCommunity}>
              {t("navbar.joinIn")}
          </Button>
        </Flex>
        <JoinCommunity isOpen={isOpen} onClose={handleCloseModal} />

      </Box>
    );
  } else {
    return null;
  }
};

export default MiniNav;
