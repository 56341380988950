import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Heading,
  Image,
  Text,
  useTheme,
  UnorderedList,
  ListItem,
  Link,
} from "@chakra-ui/react";
import { useDirection } from "../../context/DirectionContext";

const Content = ({ content, contact}) => {
const { colors } = useTheme();
const { direction } = useDirection();
  const location = useLocation();

  // Automatically scroll to the "contactus" section if it's in the URL
  useEffect(() => {
    if (location.hash === "#contactus") {
      const contactElement = document.getElementById("contactus");
      if (contactElement) {
        contactElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <Box
      textAlign={direction === "rtl" ? "right" : "left"}
      position="relative"
      width="100%"
      pt={5}
      backgroundColor={"transparent"}
      justifyContent={"center"}
    >
      <Box
        height="auto"
        width={{ base: "90%", md: "80%", lg: "80%" }}
        backgroundColor={"transparent"}
        justifyContent={"center"}
        margin={"0 auto"}
        pt={5}
      >
        {/* Title */}
        <Heading size="lg" mb={4} bg={colors.primary[200]} textStyle="Title">
          {content?.title}
        </Heading>

        {/* Join Section */}
        {content.join.map((jo, joinIndex) => (
          <Box key={joinIndex} mt={10}>
            {!!jo.title &&
            <Heading
              size="md"
              mb={5}
              pl={0}
              pr={4}
              pt={2}
              pb={2}
              color={colors.primary[300]}
              borderRadius={"xl"}
              width={"fit-content"}
            >
              {jo.title}
            </Heading>}
            <Text
              // ml={jo.title !== "" ? 4 : 0}
              textAlign={direction === "rtl" ? "right" : "left"}
              fontSize="md"
              color="gray.700"
              mb={4}
            >
              {jo.joinDescription}
            </Text>

            {jo.instructions.map((instructr, instructrIndex) => (
              <Box key={instructrIndex}>
                <Text textAlign={direction === "rtl" ? "right" : "left"} fontSize="md" color="gray.700" mb={4}>
                  {instructr.descr}
                </Text>
                {instructr?.instructr?.map((instruct, instructIndex) => (
                  <UnorderedList key={instructIndex}>
                    <ListItem 
                    // ml={4}
                    >{instruct}</ListItem>
                  </UnorderedList>
                ))}
              </Box>
            ))}
          </Box>
        ))}

        {/* Get Involved */}
        {content.getInvolved.map((involve, involveIndex) => (
          <Box key={involveIndex} >
            <Text mt={5} textAlign={direction === "rtl" ? "right" : "left"} fontSize="md" color="gray.700" mb={4}>
              {involve.title}
            </Text>
            {involve.instructions.map((instruction, instructionIndex) => (
              <Text 
              // ml={4} 
              fontWeight={'bold'}
              >{instruction}</Text>
            ))}

            {involve.how.map((h, hIndex) => (
              <Box key={hIndex}>
                <Heading
                  size="md"
                  mb={4}
                  p={2}
                  pl={4}
                  pr={4}
                  pt={2}
                  pb={2}
                  color={colors.primary[300]}
                  borderRadius={"xl"}
                  bg={
                    hIndex === 0
                      ? colors.primary[100]
                      : hIndex === 1
                        ? colors.secondary[100]
                        : hIndex === 2
                          ? colors.accent[100]
                          : colors.green[100]
                  }
                  width={"fit-content"}
                  mt={10}
                >
                  {hIndex + 1}. {h.title}
                </Heading>
                {h.descr.map((desc, descIndex) => (
                  <Box key={descIndex}>
                    <Text
                      textAlign={direction === "rtl" ? "right" : "left"}
                      fontSize="md"
                      color="gray.700"
                      mb={4}
                      // ml={4}
                    >
                      {desc.descr}: <Link color={colors.primary[300]} href={`${desc.url}`}>{desc.url}</Link> <span dangerouslySetInnerHTML={{ __html: desc.link }} />
                      <b>{desc.bold}</b>
                    </Text>
                    {h.moreInfo.map((more, moreIndex) => (
                      <Box key={moreIndex}>
                        <Text
                          textAlign={direction === "rtl" ? "right" : "left"}
                          fontSize="md"
                          color="gray.700"
                          mb={4}
                          // ml={4}
                        >
                          {more.descr}{" "}
                          <Link
                            textDecoration={"underline"}
                            color="primary.300"
                            href={
                              more?.link.includes("@")
                                ? "mailto:" + `${more.link}`
                                : `${more.link}`
                            }
                          >
                            {moreIndex === 0 && descIndex === 0
                              ? ""
                              : more.link}{" "}
                            {more.link !== "" ? <b>{more.bold}</b> : null}
                          </Link>
                          {more.link === "" ? <b>{more.bold}</b> : null}
                        </Text>

                        {more.image !== "" && (
                          <Image
                            src={`${more.image}`}
                            alt="slide"
                            width="20%"
                            objectFit="cover"
                            borderRadius="md"
                            mb={10}
                            // ml={4}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        ))}

        {/* Contact Us */}
        <Heading size="lg" mb={4} textStyle="Title" mt={10}>
          {contact?.title}
        </Heading>

        {contact?.contactUs?.map((cont, contIndex) => (
          <Box key={contIndex} id="contactus">
            <Text textAlign={direction === "rtl" ? "right" : "left"} fontSize="md" color="gray.700" mb={4}>
              {cont.question}
            </Text>
            
            <Text textAlign={direction === "rtl" ? "right" : "left"} fontSize="md" color="gray.700" mb={4}>
              {cont.description}
            </Text>
            <Text dangerouslySetInnerHTML={{ __html: cont.contact}}></Text>

          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Content;
